/* eslint-disable react/prop-types */
//import React from 'react';
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import NovaNoticia from './novanoticia';
import NoticiaLista from './noticialista';
import NoticiaEdit from './noticiaedit';

import AuthUser from '../../components/AuthUser';

const NoticiaIndex = ({ match }) => {

     // eslint-disable-next-line no-unused-vars
     const { verificarPermissao } = AuthUser();
     // eslint-disable-next-line no-unused-vars
     const [permissaoVer, setPermissaoVer] = useState(false);
     const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
     const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
     const [permissaoAtivar, setPermissaoAtivar] = useState(false);
     const [permissaoRemover, setPermissaoRemover] = useState(false);
 
     useEffect(() => {
 
         setPermissaoVer(verificarPermissao('SALA','ver'));
         setPermissaoAdicionar(verificarPermissao('SALA','adicionar'));
         setPermissaoAtualizar(verificarPermissao('SALA','atualizar'));
         setPermissaoAtivar(verificarPermissao('SALA','ativar'));
         setPermissaoRemover(verificarPermissao('SALA','remover'));
 
     },);
 
     return (
    <Switch>

        <Redirect exact from={`${match.url}/`}  render={(props) => <NovaNoticia {...props} permissaoAdicionar={permissaoAdicionar} />} />

        <Route path={`${match.url}/novanoticia`} render={(props) => <NovaNoticia {...props} permissaoAdicionar={permissaoAdicionar} />} />

        <Route path={`${match.url}/noticiaLista`}  render={(props) => <NoticiaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />

        <Route path={`${match.url}/noticiaedit/:id`} render={(props) => <NoticiaEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />} />

    </Switch>)
}

export default NoticiaIndex