/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import formatNumber from 'format-number';

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import { format } from 'date-fns';

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";


import PropTypes from 'prop-types';


const TurmaLista = ({ permissaoVer, permissaoAtivar, permissaoRemover, permissaoPagamentoVer }) => {

  const location = useLocation();
  const history = useHistory();

  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();
  const [turmas, setturma] = useState([]);
  const [cursos, setcurso] = useState([]);

  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [curso_selecionado, setCursoSelecionado] = useState([]);
  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');
  const [modoFilter, setModoFilter] = useState('');
  const [precoFilter, setPrecoFilter] = useState('');
  const [frequenciaFilter, setFrequenciaFilter] = useState('');
  const [periodoFilter, setPeriodoFilter] = useState('');
  const [coletivoFilter, setColetivoFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const niveis = ['Básico', 'Médio', 'Avançado', 'Expert'];

  //const [permissaoVer, setPermissaoVer] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedturmaIds, setSelectedturmaIds] = useState([]);

  useEffect(() => {
    // Lógica para carregar turmas apenas se a permissão permitir
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getturma()

          await getturma();
          await getcursos();


        } catch (error) {
          console.error('Erro ao obter turmas:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);


  

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao}</option>
      </React.Fragment>
    ));
  };

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

  }

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('curso_id', curso_selecionado);
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);
      formData.append('modo', modoFilter);
      formData.append('preco', precoFilter);
      formData.append('periodo', periodoFilter);
      formData.append('frequencia', frequenciaFilter);
      formData.append('paga_coletivo', coletivoFilter);
      formData.append('estado', estadoFilter);

      console.log('curso_id', curso_selecionado);
      console.log('data1', data1Filter);
      console.log('data2', data2Filter);
      console.log('modo', modoFilter);
      console.log('preco', precoFilter);
      console.log('periodo', periodoFilter);
      console.log('frequencia', frequenciaFilter);
      console.log('paga_coletivo', coletivoFilter);
      console.log('estado', estadoFilter);

      const response = await http.post('/turma/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.turmas);
      setturma(response.data.turmas);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedturmaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {


    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar turmas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/turma/eliminar-multiple', {
              selectedturmaIds,
            });

            getturma();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {
      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/turma/activate-multiple', {
          selectedturmaIds,
          newState,
        });

        getturma();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getturma = async () => {

    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/turmas');

      console.log("turmas :");
      console.log(res.data.turmas);

      setturma(res.data.turmas);

      setLoading(false);


      /* PDF E EXCEL */

      // Verifica se o array de turmas está vazio antes de prosseguir
      if (turmas.length === 0) {
        console.warn('Array de turmas vazio.');
        return;
      }

      const data = turmas?.map((turma) => ({
        name: turma.nome,
        email: turma.email,
        telefone: turma.telefone,
        estado: turma.estado === 1 ? 'Ativo' : 'Desativado',
      }));

      // Preenche os valores no estado após a conclusão de getturma()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(['Nome', 'Email', 'Telefone', 'Estado']);
      setFicheiroNome('turmas');




    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };
 
  const getcursos = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/cursos');

      setcurso(res.data.cursos);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter(null);
      setData2Filter(null);
      setModoFilter('');
      setPrecoFilter('');
      setFrequenciaFilter('');
      setPeriodoFilter('');
      setColetivoFilter('');
      setEstadoFilter();

    }



  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/turma/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getturma();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/turma/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getturma();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  function calcularTotalPagamentosFormandos(pagamentos) {
    if (!pagamentos || pagamentos.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos.reduce((total, pagamento) => total + Number(pagamento.valor), 0);
    return somaTotal;
  }


  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">

          <Link to={`/dream-pos/turma/turmaedit/${record.id}`} style={{ width: "70%" }} className="product-img">

            <img alt="imagem" className="rounded-3" src={url_root + "/storage/" + record.imagem} style={{ maxWidth: "50px" }} />

          </Link>

        </div>
      ),
      width: "80px",
    },
    {
      title: "Consulta/Unidade",
      dataIndex: "curso",
      sorter: (a, b) => a.curso?.designacao.length - b.curso?.designacao.length,
      render: (text, record) => (
        <div>
          <div> {record?.curso?.designacao?.toUpperCase()}</div>
          <div>[ {record?.paga_coletivo == 1 ? ' Publico ' : ' Empresa / Personalisado '} ]</div>
        </div>
      ), 
    },
    {
      title: "Paciente/Telefone",
      dataIndex: "sala",
      sorter: (a, b) => a.sabados.length - b.sabados.length,
      render: (text, record) => (
        <div>
          <div>{record?.atendente?.name} - {record?.atendente?.genero == 1 ? 'Masculino':'Feminino'} - {record?.atendente?.idade} anos</div>
          <div>[ {record?.atendente?.telefone} ]</div>
        </div>
      ),
    },
    {
      title: "Data/Hora",
      dataIndex: "dataIni",
      sorter: (a, b) => a.dataIni.length - b.dataIni.length,
      render: (text, record) => (
        <div>
          <div>{format(new Date(record.dataIni), 'dd/MM/yyyy')} - {record.hora1.substring(0, 5)}</div>
          <div className={record.status == 1 ? 'text-danger' : record.status == 2 ? 'text-warning' : record.status == 3 ? 'text-danger' : 'text-success'}>[ {record.status == 1 ? 'Aguarda confirmação' : record.status == 2 ? 'Confirmada' : record.status == 3 ? 'Cancelada' : 'Realizada'} ]</div>
        </div>
      ),

    },
    {
      title: "Preço ( Kz )",
      dataIndex: "preco",
      sorter: (a, b) => a.preco.length - b.preco.length,
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div title={"Percentagem de pagamento atual para as " + record?.sala?.acentos + " vagas previstas"}>[ {formatarNumero(Math.round(calcularTotalPagamentosFormandos(record.pagamentos) * 100) / (record.preco * record.formandos.length),1,'.',',') }% ]</div>
        </div>
      ),
    },
    {
      title: "Modo",
      dataIndex: "modo",
      sorter: (a, b) => a.sabados.length - b.sabados.length,
      render: (text, record) => record.modo == 1 ? 'Presencial' : 'Online'
    },   
    {
      title: "Ação",
      render: (e, record) => (
        <>
          <div className="icons-container">

            <Link title="editar dados cliente" className="me-3" to={`/dream-pos/turma/turmaedit/${record.id}`}>
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div title="Eliminar este cliente" className="confirm-text" onClick={() => confirmEliminar(record.id)}>
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

        </>
      ),
    },
  ];

  
  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };
  
    return formatNumber(options)(numero);
  };

  if (permissaoVer)
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i alt="Image" className="header-image fa fa-book border p-3 rounded-3"></i>
                <div>
                  <h4>Gestão de consultas</h4>
                  <h6>Lista de consultas</h6>
                </div>
              </div>
              <div className="page-btn">
                <Link to="/dream-pos/turma/novaturma" className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  Adicionar consulta
                </Link>
              </div>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">

                <Tabletop inputfilter={inputfilter}
                  togglefilter={togglefilter}
                  handleSelectedItemChange={handleSelectedItemChange}
                  handleEliminarMultiplos={handleEliminarMultiplos}
                  generatePDF={generatePDF}
                  generateExcel={generateExcel}
                  logoTipo={logoTipo}
                  tableHeader={tableHeader}
                  tableData={tableData}
                  ficheiroNome={ficheiroNome}
                  setNomeFilter={setNomeFilter}
                  handleFilterSubmit={handleFilterSubmit}
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            name="curso_id"
                            onChange={(e) => handleSelectCursoChange(e.target.value)}
                            value={curso_selecionado}
                            className="form-control select"
                          >
                            <option value={-1}>Selecione a consulta</option>
                            {renderOptioncursos(cursos)}

                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group" title="data 1">
                          <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                            onChange={(e) => setData1Filter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                        <div className="form-group">
                          <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                            onChange={(e) => setData2Filter(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="number" className="form-control" placeholder="Preço" value={precoFilter}
                            onChange={(e) => setPrecoFilter(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" value={modoFilter} onChange={(e) => setModoFilter(e.target.value)}
                          >
                            <option value="-1">Todos modos</option>
                            <option value="1">Presencial</option>
                            <option value="2">Online</option>
                            <option value="3">Hibrido</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="1">Aguardando Confirmação</option>
                            <option value="2">Confirmada</option>
                            <option value="3">Cancelada</option>
                            <option value="4">Realizada</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" value={frequenciaFilter} onChange={(e) => setFrequenciaFilter(e.target.value)}
                          >
                            <option value="-1">Todas as frequências</option>
                            <option value="1">Dias utéis</option>
                            <option value="2">Sábados</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" value={periodoFilter} onChange={(e) => setPeriodoFilter(e.target.value)}
                          >
                            <option value="-1">Todos os periodos</option>
                            <option value="1">Manhã</option>
                            <option value="2">Tarde</option>
                            <option value="3">Noite</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" value={coletivoFilter} onChange={(e) => setColetivoFilter(e.target.value)}
                          >
                            <option value="-1">Todos os pacientes</option>
                            <option value="1">Publico</option>
                            <option value="2">Seguro de Saúde </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">

                  {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                  ) : (

                    /*<Table columns={columns} dataSource={turmas} />*/

                    <Datatable
                      columns={columns}
                      dataSource={turmas}
                      onSelectedRowsChange={(selectedturmaIds) =>
                        setSelectedturmaIds(selectedturmaIds)
                      }

                    />
                  )}

                </div>
              </div>
            </div>
            {/* /product list */}

          </div>
        </div>

        <ToastContainer />

      </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

TurmaLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
  permissaoPagamentoVer: PropTypes.bool.isRequired,
};

export default TurmaLista;
