/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../components/Spinner";

import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

import PropTypes from "prop-types";

const Newformador = ({ permissaoAdicionar, permissaoAcessoAtualizar }) => {
  const history = useHistory();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user } = AuthUser();
  const [name, setName] = useState("");
  //eslint-disable-next-line no-unused-vars
  const [telefone, setTelefone] = useState("");
  const [nivel_academico, setAcademico] = useState("");
  const [data_nasc, setDataNasc] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [endereco, setEndereco] = useState("");
  const [habilidades, setHabilidade] = useState("");
  //eslint-disable-next-line no-unused-vars
  const [nivel_id, setNivel] = useState(0);
  const [tipo, setTipo] = useState(1);
  const [niveis, setNiveis] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [nivelSelecionado, setNivelSelecionado] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fotografia, setSelectedFile] = useState(null);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  /* CONST INICIO PERMISSÕES */

  const [accoes, setAccoes] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  /* CONSTS FIM PERMISSÕES */

  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("nivel_id", nivelSelecionado);

      const res = await http.post("/permissoes", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setPermissoes(res.data.permissoes);

      console.log("Permissões :");
      console.log(res.data.permissoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os permissões:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get("/accoes");

      setAccoes(res.data.accoes);

      console.log("Acções");
      //console.log(res.data.accoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false);
    }
  };

  /* fim get permissões */

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaLista = () => {
    history.push("/dream-pos/users/formadorlists");
  };

  const navegarParaEdicao = (id) => {
    history.push("/dream-pos/users/newformadoredit/" + id);
  };

  useEffect(() => {
    setTipo(2); //Tipo formador
    getNiveis();
  }, []);

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/niveis");

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

      setNivel(res.data.niveis[0].id);

      console.log("Fetching permissoes...");

      setNivelSelecionado(res.data.niveis[0].id);

      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("nivel_id", res.data.niveis[0].id);

        const res = await http.post("/permissoes", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setPermissoes(res.data.permissoes);

        console.log("Permissões :");
        console.log(res.data.permissoes);
      } catch (error) {
        console.error("Ocorreu um erro ao carregar os permissões:", error);
      } finally {
        setLoading(false);
      }

      console.log("Permissoes:", permissoes);

      console.log("Fetching accões...");
      await getAccoes();
      console.log("Acções:", accoes);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAdicionar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (name.length < 2) {
          setError("O nome deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("O nome deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(email)) {
          setError("O email deve ser válido.");
          toggleLoading(false);

          // Show a error toast
          toast.error("O email deve ser válido.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (password.length < 6) {
          setError("A senha deve ter pelo menos seis caracteres.");
          toggleLoading(false);

          // Show a error toast
          toast.error("A senha deve ter pelo menos seis caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (password !== password2) {
          setError("As senhas não coincidem.");
          toggleLoading(false);

          // Show a error toast
          toast.error("As senhas não coincidem.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadUtilizador();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadUtilizador = async () => {
    if (permissaoAdicionar) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("nivel_id", nivel_id);
        formData.append("tipo", tipo);
        formData.append("telefone", telefone);
        formData.append("fotografia", fotografia);

        formData.append("data_nasc", data_nasc);
        formData.append("genero", genero);
        formData.append("nivel_academico", nivel_academico);
        formData.append("endereco", endereco);
        formData.append("habilidades", habilidades);

        formData.append("estado", 0);
        formData.append("empresa_id", user.empresa_id);

        const response = await http.post("/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de utilizadores após o cadastro bem-sucedido
            //history.push('/dream-pos/users/userlists');

            setName("");
            setEmail("");
            setTelefone("");
            setPassword("");
            setPassword2("");
            setSelectedFile(null);

            if (!permanecerNaPagina) {
              navegarParaLista();
            } else {
              navegarParaEdicao(response.data.data.id);
            }
          },
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  /* INICIO PERMISSÕES */

  const handleCheckboxChange = async (nivelId, acaoId, action) => {
    if (permissaoAcessoAtualizar) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("nivel_id", nivelId);
        formData.append("accao_id", acaoId);
        formData.append("tipo", action);

        // Set the value based on the current state (toggle)
        const valor = permissoes.some(
          (obj) =>
            obj.nivel_id === nivelId &&
            obj.accao_id === acaoId &&
            obj[action] === 1
        )
          ? 0
          : 1;
        formData.append("value", valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post("/update-permissao", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }
      } catch (error) {
        // Show a success toast
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleAtivarDesativarLinha = async (
    nivelSelecionado,
    accao_id,
    valor
  ) => {
    if (permissaoAcessoAtualizar) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("nivel_id", nivelSelecionado);
        formData.append("accao_id", accao_id);
        formData.append("valor", valor);

        console.log(nivelSelecionado, accao_id, valor);

        const res = await http.post("/update-permissao-multipla", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }
      } catch (error) {
        // Show a success toast
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(
      `.chk_selecionar_linhas_${index}`
    ).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document
      .querySelectorAll(`.chk_linha_permissao_${index}`)
      .forEach((checkbox) => {
        checkbox.checked = selectAllChecked;
        // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
        // Substitua os valores de nivelSelecionado e cdata.id pelos valores corretos
      });

    const valor = selectAllChecked ? 1 : 0;

    console.log("Valor todos :");
    console.log(valor);

    handleAtivarDesativarLinha(nivelSelecionado, accao_id, valor);
  };

  const handleSelectedAll = async () => {
    if (permissaoAcessoAtualizar) {
      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll(".chk_selecionar_linhas");

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute("code");
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append("nivel_id", nivelSelecionado);
          formData.append("accao_id", accao_id);
          formData.append("valor", valor);

          const res = await http.post("/update-permissao-multipla", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectAcessoChange = (id) => {
    setNivelSelecionado(id);
    setNivel(id);

    console.log(id);

    // Fetch new permissions after navigation
    getPermissoes(id);
  };

  /* FIM PERMISSÕES */

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-users border p-3 rounded-3"
            ></i>
            <div>
              <h4>Gestão de doctores</h4>
              <h6>Adicionar doctor</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/users/formadorlists" className="btn btn-added">
              <i className="fa fa-table"></i>&nbsp; Lista de doctores
            </Link>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Nome do doctor</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>E-mail</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Senha</label>
                  <div className="pass-group">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className=" pass-input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className={`fa toggle-password ${
                        passwordShown ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePassword}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Genero</label>

                  <select
                    className="form-control select"
                    onChange={(e) => setGenero(e.target.value)}
                  >
                    <option value="1">Feminino</option>
                    <option value="2">Masculino</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Data Nascimento</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data_nasc}
                    onChange={(e) => setDataNasc(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Citação</label>
                  <textarea
                    className="form-control"
                    value={habilidades}
                    onChange={(e) => setHabilidade(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Telefone</label>
                  <input
                    type="text"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Nivel</label>

                  <select
                    onChange={(e) => handleSelectAcessoChange(e.target.value)}
                    className="form-control select"
                    value={nivelSelecionado}
                  >
                    {niveis?.map((cdata) => (
                      <option key={cdata.id} value={cdata.id}>
                        {cdata.designacao}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Confirmar senha</label>
                  <div className="pass-group">
                    <input
                      type={passwordShown1 ? "text" : "password"}
                      className=" pass-input"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <span
                      className={`fa toggle-password ${
                        passwordShown1 ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePassword1}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Nivel Académico</label>
                  <input
                    type="text"
                    value={nivel_academico}
                    onChange={(e) => setAcademico(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Endereço</label>
                  <input
                    type="text"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  {/* Checkbox "Permanecer na Página" */}
                  <label>
                    <input
                      type="checkbox"
                      checked={permanecerNaPagina}
                      onChange={handlePermanecerNaPagina}
                    />
                    &nbsp; Permanecer na Página depois de salvar
                  </label>
                </div>
              </div>
              {/* Restante do código */}
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Fotografia 500X700</label>
                  <div className="image-upload image-upload-new">
                    <input type="file" onChange={handleFileChange} />
                    <div className="image-uploads">
                      {fotografia ? (
                        <>
                          <img
                            src={URL.createObjectURL(fotografia)}
                            alt="Pré-visualização"
                          />
                          <h4>Carregar fotografia</h4>
                        </>
                      ) : (
                        <h4>Carregar fotografia</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Restante do código */}
              <div className="col-lg-12">
                <a onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : "Salvar"}
                </a>
                <a onClick={navegarParaLista} className="btn btn-cancel">
                  Cancelar
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /add */}

        {/* INICIO PERMISSÕES */}

        {permissaoAdicionar == 2 ? (
          <>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="input-checkset">
                      <ul>
                        <li>
                          <label className="inputcheck">
                            Selecionar tudo
                            <input
                              type="checkbox"
                              id="select-all"
                              onChange={() => handleSelectedAll()}
                            />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="productdetails product-respon">
                      <ul>
                        {loading ? (
                          <Spinner />
                        ) : (
                          accoes?.map((cdata, index) => {
                            // Find the corresponding object in your array
                            const matchingObject = permissoes?.find((obj) => {
                              return (
                                obj.accao_id == cdata.id &&
                                obj.nivel_id == nivelSelecionado
                              );
                            });

                            return (
                              <li key={index}>
                                <h4 title={cdata.descricao}>
                                  {cdata.designacao}
                                </h4>
                                <div className="input-checkset">
                                  <ul>
                                    <li>
                                      <label className="inputcheck">
                                        Ver
                                        <input
                                          key={cdata.id + "ver"}
                                          type="checkbox"
                                          checked={
                                            matchingObject &&
                                            matchingObject.ver == 1
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              nivelSelecionado,
                                              cdata.id,
                                              "ver"
                                            )
                                          }
                                          className={
                                            "chk_linha_permissao_" + index
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>

                                    <li>
                                      <label className="inputcheck">
                                        Adicionar
                                        <input
                                          type="checkbox"
                                          key={cdata.id + "adicionar"}
                                          checked={
                                            matchingObject &&
                                            matchingObject.adicionar == 1
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              nivelSelecionado,
                                              cdata.id,
                                              "adicionar"
                                            )
                                          }
                                          className={
                                            "chk_linha_permissao_" + index
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>

                                    <li>
                                      <label className="inputcheck">
                                        Atualizar
                                        <input
                                          type="checkbox"
                                          key={cdata.id + "atualizar"}
                                          checked={
                                            matchingObject &&
                                            matchingObject.atualizar == 1
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              nivelSelecionado,
                                              cdata.id,
                                              "atualizar"
                                            )
                                          }
                                          className={
                                            "chk_linha_permissao_" + index
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>

                                    <li>
                                      <label className="inputcheck">
                                        Ativar/Desativar
                                        <input
                                          type="checkbox"
                                          key={cdata.id + "ativar"}
                                          checked={
                                            matchingObject &&
                                            matchingObject.ativar == 1
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              nivelSelecionado,
                                              cdata.id,
                                              "ativar"
                                            )
                                          }
                                          className={
                                            "chk_linha_permissao_" + index
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>

                                    <li>
                                      <label className="inputcheck">
                                        Remover
                                        <input
                                          type="checkbox"
                                          key={cdata.id + "remover"}
                                          checked={
                                            matchingObject &&
                                            matchingObject.remover == 1
                                          }
                                          onChange={() =>
                                            handleCheckboxChange(
                                              nivelSelecionado,
                                              cdata.id,
                                              "remover"
                                            )
                                          }
                                          className={
                                            "chk_linha_permissao_" + index
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>

                                    <li>
                                      <label className="inputcheck">
                                        Selecionar tudo
                                        <input
                                          type="checkbox"
                                          checked={
                                            matchingObject &&
                                            matchingObject.ver == 1 &&
                                            matchingObject.adicionar &&
                                            matchingObject.atualizar &&
                                            matchingObject.ativar &&
                                            matchingObject.remover
                                          }
                                          key={cdata.id + "select"}
                                          onChange={() =>
                                            handleSelectRow(index, cdata.id)
                                          }
                                          className={
                                            "chk_selecionar_linhas_" +
                                            index +
                                            " chk_selecionar_linhas"
                                          }
                                          code={cdata.id}
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* FIM PERMISSÕES */}
      </div>

      <ToastContainer />
    </div>
  );
};

Newformador.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired,
  permissaoAcessoAtualizar: PropTypes.bool.isRequired,
};

export default Newformador;
