/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';

export const ModalParagrafoNovo = ({ onSubmissionSuccess, tipo, tipo_id }) => {

    // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();

  const [loading, setLoading] = useState(false);

  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ordem, setOrdem] = useState(1);
  const [icone, setIcone] = useState("");
  const [imagem200, setSelectedFile200] = useState(null); //comprovativo de pagamento 2
  const [imagemPreview200, setimagemPreview200] = useState(null);

  const handleFileChange200 = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile200(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview200(newFileURL);

    // Restante do código
  };


  const registarParagrafo = async () => {
    try {
      const formData = new FormData();

      formData.append('titulo', titulo);
      formData.append('descricao', descricao);
      formData.append('icone', icone);
      formData.append('ordem', ordem);
      formData.append('tipo', tipo);
      formData.append('tipo_id', tipo_id);
      formData.append('imagem', imagem200);

      const response = await http.post('/paragrafo/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response);

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de turmas após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');

          setTitulo("");
          setDescricao("");
          setOrdem("");
          setIcone("");

          onSubmissionSuccess();
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {


      try {

        toggleLoading(true);


        await registarParagrafo();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          
          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    



  };

  return (
    <div>
      {/* Add Paragrafo Modal */}
      <div
        id="modal_novo_paragrafo"
        className="modal modal-md  fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-lg modal-center">
            <div className="modal-header">
              <h5 className="modal-title">
                {" "}
                <i className="fa fa-list"></i>&nbsp; Registar Paragrafo
              </h5>

              <a
                id="trigger_modal_novo_paragrafo"
                href="#"
                className=""
                data-bs-toggle="modal"
                data-bs-target="#modal_novo_paragrafo"
              ></a>

              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"></span>
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Titulo</label>
                      <input
                        type="text"
                        name="titulo"
                        className="form-control"
                        onChange={(e) => setTitulo(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label>DESCRIÇÃO</label>
                      <textarea
                        className="form-control"
                        name="observacao"
                        onChange={(e) => setDescricao(e.target.value)}
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label>ORDEM</label>
                      <input
                        type="number"
                        name="ordem"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setOrdem(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label>Icone</label>
                      <input
                        type="text"
                        name="icone"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setIcone(e.target.value)}
                      />
                    </div>

                    <div className="form-group"> 
                      <a className="btn btn-primary" onClick={submitForm}>
                        {loading ? 'Registando...' : "REGISTAR PARAGRAFO"}
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Imagem</label>
                      <div className="image-upload image-upload-new">
                        <input type="file" onChange={handleFileChange200} />
                        <div className="image-uploads">
                          {imagemPreview200 ? (
                            <>
                              <img
                                id="imagem200"
                                src={
                                  imagemPreview200
                                    ? imagemPreview200
                                    : URL.createObjectURL(imagem200)
                                }
                                alt="Pré-visualização"
                              />
                              <h4>Carregar imagem</h4>
                            </>
                          ) : (
                            <>
                              <img id="imagem" alt="Pré-visualização" />
                              <h4>Carregar imagem de comprovativo</h4>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Event Modal */}

      <ToastContainer />

    </div>
  );
};

ModalParagrafoNovo.propTypes = {
    onSubmissionSuccess: PropTypes.bool.isRequired,
    tipo: PropTypes.number.isRequired,
    tipo_id: PropTypes.number.isRequired,
  };
  
