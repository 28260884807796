/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from "prop-types";
import { ModalParagrafoNovo } from "../modal/modalParagrafoNovo";
import { ModalParagrafoEditar } from "../modal/modalParagrafoEditar";

const ParagrafoLista = ({
  permissaoVer,
  permissaoAtivar,
  permissaoRemover,
}) => {
  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } =
    AuthUser();
  const [paragrafos, setParagrafos] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState("");

  const [designacaoFilter, setDesignacaoFilter] = useState("");
  const [descricaoFilter, setDescricaoFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [paginaFilter, setPaginaFilter] = useState(1);
  const [paragrafo_id, setParagrafoID] = useState(0);
  const [tituloParam, setTitulo] = useState(0);
  const [descricaoParam, setDescricao] = useState("");
  const [ordemParam, setOrdem] = useState(1);
  const [iconeParam, setIcone] = useState("");
  const [imagemParam, setImagem] = useState("");

  
  

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedsalaIds, setSelectedsalaIds] = useState([]);

  

  const handleAbrirModalEditarParagrafo = (id,titulo,descricao,icone,imagem,ordem) => {


    setParagrafoID(id);
    setTitulo(titulo);
    setDescricao(descricao);
    setIcone(icone);
    setImagem(imagem);
    setOrdem(ordem);
   
    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById('trigger_modal_editar_paragrafo');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }

  }
 

  const handleAbrirModalParagrafo = (tipo,tipo_id) => {

   
    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById('trigger_modal_novo_paragrafo');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }

  }

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getParagrafos(paginaFilter)
          await getParagrafos(paginaFilter);
        } catch (error) {
          console.error("Erro ao obter paragrafos:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("titulo", designacaoFilter);
      formData.append("descricao", descricaoFilter);
      formData.append("estado", estadoFilter);

      const response = await http.post("/paragrafo/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.salas);
      setParagrafos(response.data.paragrafos);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedsalaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {
    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar salas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post("/banner/eliminar-multiple", {
              selectedsalaIds,
            });

            getParagrafos(paginaFilter);

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {
    if (permissaoAtivar) {
      try {
        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post("/banner/activate-multiple", {
          selectedsalaIds,
          newState,
        });

        getParagrafos(paginaFilter);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getParagrafos = async (pagina) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/paragrafos_de/" + pagina + "/0");

      //console.log("salas :");
      //console.log(res.data.salas);

      setParagrafos(res.data.paragrafos);

      setLoading(false);

      /* pdf e excel */

      // Verifica se o array de salas está vazio antes de prosseguir
      if (paragrafos.length === 0) {
        console.warn("Array de pragrafos vazio.");
        return;
      }

      const data = paragrafos.map((sala) => ({
        name: sala.designacao,
        descricao: sala.descricao,
        estado: sala.estado === 1 ? "Ativo" : "Desativado",
      }));

      // Preenche os valores no estado após a conclusão de getParagrafos(paginaFilter)
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(["Designação", "Descrição", "Estado"]);
      setFicheiroNome("salas");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("sala não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const onSubmissionSuccess = () => {

    mostrarParagrafos(paginaFilter);

  };

  const mostrarParagrafos = async (pagina) => {

    setPaginaFilter(pagina);

    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/paragrafos_de/" + pagina + "/0");

      //console.log("salas :");
      //console.log(res.data.salas);

      setParagrafos(res.data.paragrafos);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {
    if (permissaoRemover) {
      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {
        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {
      setDesignacaoFilter("");
      setDescricaoFilter("");
      setEstadoFilter();

      getParagrafos(paginaFilter);
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/banner/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getParagrafos(paginaFilter);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {
    if (permissaoRemover) {
      try {
        const formData = new FormData();
        formData.append("id", id);

        const response = await http.post("/paragrafo/eliminar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getParagrafos(paginaFilter);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" style={{ width: "70%" }} className="product-img">
            <img
              alt="imagem"
              className="rounded-3"
              src={(record.imagem == 'null' || record.imagem == null) ? url_root + "/storage/sample.WEBP" : url_root + "/storage/" + record.imagem}
              style={{ maxWidth: "50px" }}
            />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      title: "Ordem",
      dataIndex: "ordem",
      sorter: (a, b) => a.ordem.length - b.ordem.length,
    },
    {
      title: "Texto",
      dataIndex: "titulo",
      sorter: (a, b) => a.designacao.length - b.designacao.length,
      render: (e, record) => (
        <>
          <div className="icons-container">
            <div title="editar dados paragrafo" className="me-3" to="#">
              <img src={EditIcon} alt="Editar" onClick={()=>handleAbrirModalEditarParagrafo(record?.id,record?.titulo,record?.descricao,record?.icone,record?.imagem,record?.ordem)} />
            </div>
            <div
              title="Eliminar esta paragrafo"
              className="confirm-text"
              onClick={() => confirmEliminar(record.id)}
            >
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

          <div>{record?.titulo}</div>

          <div className="row"><div className="col-sm-2">{record?.descricao}</div></div>
        </>
      ),
    },
  ];

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">
                  <i
                    alt="Image"
                    className="header-image fa fa-university border p-3 rounded-3"
                  ></i>
                  <div>
                    <h4>Gestão de paragrafos</h4>
                    <h6>Lista de paragrafos</h6>
                  </div>
                </div>
                <div className="page-btn">  
                  <div
                    onClick={() => handleAbrirModalParagrafo(1,1)}
                    className="btn btn-added"
                  >
                    <img src={PlusIcon} alt="img" className="me-1" />
                    Adicionar paragrafo
                  </div>
                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">
                  <Tabletop
                    inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    setDesignacaoFilter={setDesignacaoFilter}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Designação"
                              value={designacaoFilter}
                              onChange={(e) =>
                                setDesignacaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Descrição"
                              value={descricaoFilter}
                              onChange={(e) =>
                                setDescricaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => setEstadoFilter(e.target.value)}
                            >
                              <option value="-1">Todos estados</option>
                              <option value="0">Desativado</option>
                              <option value="1">Ativado</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img
                                src={search_whites}
                                alt="img"
                                onClick={handleFilterSubmit}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">
                    {loading ? (
                      <span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (
                      /*<Table columns={columns} dataSource={salas} />*/

                      <>
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-6 col-sm-6 col-12">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      mostrarParagrafos(e.target.value)
                                    }
                                  >
                                    <option value="1" selected={paginaFilter == 1}>Pagina : Inicio</option>
                                    <option value="2" selected={paginaFilter == 2}>
                                      Pagina : Sobre Nós
                                    </option>
                                    <option value="3" selected={paginaFilter == 3}>
                                      Pagina : Especialistas
                                    </option>
                                    <option value="4" selected={paginaFilter == 4}>Pagina : Faqs</option>
                                    <option value="5" selected={paginaFilter == 5}>Pagina : Unidades</option>
                                    <option value="6" selected={paginaFilter == 6}>Pagina : Serviços</option>
                                    <option value="7" selected={paginaFilter == 7}>Pagina : Noticias</option>
                                    <option value="8" selected={paginaFilter == 8}>
                                      Pagina : Contactos
                                    </option>
                                    <option value="9" selected={paginaFilter == 9}>
                                      Pagina : Footer Contactos
                                    </option>
                                    <option value="10" selected={paginaFilter == 10}>
                                      Secção : Marcar consulta
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Datatable
                          columns={columns}
                          dataSource={paragrafos}
                          onSelectedRowsChange={(selectedsalaIds) =>
                            setSelectedsalaIds(selectedsalaIds)
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>


          <ModalParagrafoNovo onSubmissionSuccess={onSubmissionSuccess} tipo={paginaFilter} tipo_id={0} />

          <ModalParagrafoEditar onSubmissionSuccess={onSubmissionSuccess} tipo={paginaFilter} tipo_id={0} id={paragrafo_id} tituloParam={tituloParam} descricaoParam={descricaoParam} iconeParam={iconeParam} imagemParam={imagemParam} ordemParam={ordemParam} />

          </div>
 
          <ToastContainer />
        </>
        );{" "}
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

ParagrafoLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default ParagrafoLista;
