/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import { format } from 'date-fns';

import PropTypes from 'prop-types';

import {
  Excel,
  Pdf,
} from "../../EntryFile/imagePath";


const NewformandoEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoAcessoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();

  const [nivelSelecionado, setNivelSelecionado] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [utilizador, setUtilizador] = useState(null);
  const [loading, setLoading] = useState(false);
  const [niveis, setNiveis] = useState([]);
  const [tipo, setTipoSelecionado] = useState(1);
  const [genero, setGenero] = useState(1);

  
  const [imagem200, setSelectedFile200] = useState(null);//comprovativo de pagamento 2
  const [imagemPreview200, setimagemPreview200] = useState(null);

  
  const [valor3, setValor3] = useState(0);
  const [observacao3, setObservacao3] = useState('');
  const [tipo_pagamento, setPagamento] = useState(2);

  const [inscricao_selecionada, setInscricaoID] = useState(null);
  const [turma_selecionada, setTurmaSelecionada] = useState(null);

  
  const [data_insc3, setDataInsc3] = useState(new Date());

  const [fotografia, setSelectedFile] = useState(null);
  const [fotografiaPreview, setFotografiaPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  const [turmas, setTurmas] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const [pagamentos_turma, setPagamentosTurma] = useState([]);
  const [cursos, setcurso] = useState([]);

  const [tipoPagamento, setTipoPagamento] = useState(1);


  const [galeria, setGaleria] = useState(1);
  
  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  };

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis');

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getcursos = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/cursos');

      setcurso(res.data.cursos);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', nivelSelecionado);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões :');
      console.log(res.data.permissoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      console.log('Acções');
      //console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

  /* fim get permissões */

  /* CONSTS INICIO PERMISSÕES */

  const [accoes, setAccoes] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  /* CONSTS FIM PERMISSÕES */

  const navegarParaLista = () => {
    history.push('/dream-pos/users/formandolists');
  };

  const irParaTurma = (id) => {
    history.push('/dream-pos/turma/turmaedit/' + id);
  }

  useEffect(() => {

    setTipoSelecionado(3); //Para formando

    const fetchData = async () => {
      try {

        console.log('Fetching niveis...');
        await getNiveis();
        console.log('Niveis:', niveis);

        console.log('Fetching user data...');
        const userResponse = await http.get('/utilizador/' + id);
        console.log('User data:', userResponse.data.utilizador);

        setUtilizador(userResponse.data.utilizador);

        if (userResponse.data.utilizador?.tipo == 3) {

          setInputs(userResponse.data.utilizador);
          setNivelSelecionado(userResponse.data.utilizador.nivel_id);
          setGenero(userResponse.data.utilizador.genero);
          setTipoSelecionado(userResponse.data.utilizador.tipo);
          setFotografiaPreview(userResponse.data.utilizador.fotografia);

          console.log('Fetching permissoes...');
          try {

            setLoading(true);

            const formData = new FormData();
            formData.append('nivel_id', userResponse.data.utilizador.nivel_id);

            const res = await http.post('/permissoes', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            setPermissoes(res.data.permissoes);

            console.log('Permissões :');
            console.log(res.data.permissoes);

          } catch (error) {
            console.error('Ocorreu um erro ao carregar os permissões:', error);
          } finally {
            setLoading(false);
          }

          console.log('Permissoes:', permissoes);

          console.log('Fetching accões...');
          await getAccoes();
          console.log('Acções:', accoes);
        }


        await getcursos();
        await getPagamentos();
        await getTurmas();



      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [id]);


  const getTurmas = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/turmas/formando/' + id);

      setTurmas(res.data.turmas);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };



  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const handleResetSenha = (id) => {

    if (permissaoAtualizar) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer resetar a senha?",
        text: "Isso irá resetar a senha do usuário.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, resetar senha!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação para resetar a senha
          resetarSenha(id);
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const resetarSenha = async (id) => {

    if (permissaoAtualizar) {
      try {
        // Gere uma senha aleatória no cliente
        const senhaAleatoria = Math.random().toString(36).slice(-8);

        // Exiba a senha antes de enviar a solicitação
        alert(`Nova Senha: ${senhaAleatoria}`);

        // Envie uma solicitação para a API para resetar a senha
        const response = await http.post(`/resetar-senha/${id}`, { novaSenha: senhaAleatoria });

        // Exiba uma mensagem de sucesso
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


      } catch (error) {
        // Exiba uma mensagem de erro se ocorrer um problema
        toast.error('Ocorreu um erro ao resetar a senha. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getUtilizador = async () => {
    try {
      /* */
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizador/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.utilizador);

      setNivelSelecionado(res.data.utilizador.nivel_id);

      setFotografiaPreview(res.data.utilizador.fotografia);

      console.log('User :');
      console.log(res.data.utilizador);

      console.log('Accao_id :');
      console.log(nivelSelecionado);

      //getPermissoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/utilizador/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getUtilizador();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      nivel_id: name === 'nivel_id' ? value : prevInputs.nivel_id,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('fotografia').src = newFileURL;

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.name.length < 2) { 
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(inputs.email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);

          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateUtilizador();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const updateUtilizador = async () => {

    try {

      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', inputs.name);
      formData.append('email', inputs.email);
      formData.append('nivel_id', nivelSelecionado);
      formData.append('tipo', tipo);
      formData.append('nif', inputs.nif);
      formData.append('telefone', inputs.telefone);
      formData.append('data_nasc', inputs.data_nasc);
      formData.append('genero', genero);
      formData.append('nivel_academico', inputs.nivel_academico);
      formData.append('endereco', inputs.endereco);
      formData.append('fotografia', fotografia);
      formData.append('galeria', galeria);

      const response = await http.post('/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de utilizadores após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  };


  // Função utilitária para formatar números
  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    return numero.toLocaleString('pt-BR', {
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
      style: 'decimal',
    })
      .replace('.', separadorDecimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separadorMilhares}`);
  };


  /* INICIO PERMISSÕES */

  const handleCheckboxChange = async (nivelId, acaoId, action) => {

    if (permissaoAcessoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelId);
        formData.append('accao_id', acaoId);
        formData.append('tipo', action);

        // Set the value based on the current state (toggle)
        const valor = permissoes.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
        formData.append('value', valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post('/update-permissao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleAtivarDesativarLinha = async (nivelSelecionado, accao_id, valor) => {

    if (permissaoAcessoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelSelecionado);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);


        console.log(nivelSelecionado, accao_id, valor);

        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de nivelSelecionado e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(nivelSelecionado, accao_id, valor);
  };

  const handleSelectedAll = async () => {

    if (permissaoAcessoAtualizar) {

      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute('code');
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append('nivel_id', nivelSelecionado);
          formData.append('accao_id', accao_id);
          formData.append('valor', valor);

          const res = await http.post('/update-permissao-multipla', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectAcessoChange = (id) => {

    setNivelSelecionado(id);

    console.log(id);

    // Fetch new permissions after navigation
    getPermissoes(id);

  };


  const handleSelectGeneroChange = (id) => {

    setGenero(id);

  };

  /* FIM PERMISSÕES */


  function calcularSomaTotalPagamentos(pagamentos) {
    if (!pagamentos || pagamentos.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos.reduce((total, pagamento) => total + pagamento.valor, 0);
    return somaTotal;
  }


  const CursoDaTurma = (curso_id) => {

    const cursoEncontrado = cursos?.find((lista) => lista.id == curso_id);

    // Verifique se o objeto foi encontrado
    if (cursoEncontrado != undefined) {
      return cursoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const getPagamentos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('/pagamentos/formando/' + id);

      console.log(response.data);

      setPagamentosTurma(response.data.pagamentos);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const remover_pagamento = async (pagamento_id) => {
    if (permissaoAtualizar) {

      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende remover este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            const formData = new FormData();

            formData.append('pagamento_id', pagamento_id);

            setLoading(true);

            const response = await http.post('/turma/formando/remover_pagamento', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            getTurmas();

            getPagamentos();

            setLoading(false);

            // Show a success toast

            if (response?.data)
              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                onClose: () => {
                }
              });
            else toast.error('Erro : ' + response.error, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          } catch (error) {
            console.log("Erro:");
            console.log(error);

            toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const handleRemoverInscricao = (id) => {

    if (permissaoAtualizar) {

      Swal.fire({
        title: "Tem a certeza que pretende remover esta inscrição ?",
        text: "Todos os pagamentos associados a esta inscrição tambem serão removidos, não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          removerInscricao(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const removerInscricao = async (id) => {

    if (permissaoAtualizar) {

      try {

        const formData = new FormData();
        formData.append('inscricao_id', id);

        const response = await http.post('/turma/remover/inscricao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getTurmas();

        getPagamentos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao remover a inscrição. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  
  const submitPagamentoForm = async () => {

    if (permissaoAtualizar) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende registar este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {
    
            if (valor3 == 0 || valor3 == null || valor3 < 0) {
              // Show a error toast
              toast.error('Valor de pagamento invalido, o valor deve ser um número maior que zero', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            registar_pagamento();

          } catch (error) {
            if (error.response && error.response.status === 401) {
              setError('Utilizador não autenticado.');

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {


              setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar_pagamento = async () => {

    try {

      const formData = new FormData();


      formData.append('turma_id', turma_selecionada);
      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('tipo_pagamento', tipo_pagamento);

      formData.append('observacao', observacao3);
      formData.append('comprovativo', imagem200);
      formData.append('valor', valor3);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('data_inscricao', data_insc3);

      formData.append('dia', obterInfoNaData(data_insc3, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc3, 2));
      formData.append('ano', obterInfoNaData(data_insc3, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/registar_pagamento', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao3('');
      setValor3(0);
      setimagemPreview200('');

      getTurmas();

      getPagamentos();


      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleFileChange200 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile200(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview200(newFileURL);

    // Restante do código
  };

  //tipo = 1 retorna o ano, tipo = 2 retorna o mês e tipo = 3 retorna o dia
  function obterInfoNaData(data, tipo) {

    const data1 = new Date(data);

    if (tipo == 1)
      return data1.getFullYear();
    else if (tipo == 2)
      return data1.getMonth();
    else
      return data1.getDate();
  }   
  
  
  

  const handleAbrirModalPagamento = (inscricao_id, turma_id, paga_coletivo) => {

    setInscricaoID(inscricao_id);
    setTurmaSelecionada(turma_id);
    setPagamento(paga_coletivo);

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_pagamento');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  } 


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de paciente</h4>
              <h6>Editar paciente</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/users/newformando" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp; Add Novo Paciente
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nome do paciente</label>
                    <input type="text" name="name" id="nome" value={inputs.name} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>E-mail</label>
                    <input id="email" name="email" type="text" value={inputs.email} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Genero</label>

                    <select className="form-control select" onChange={e => handleSelectGeneroChange(e.target.value)} value={genero} >
                      <option value="1" selected={inputs.genero == 1}>
                        Feminino
                      </option>
                      <option value="2" selected={inputs.genero == 2}>
                        Masculino
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Nascimento</label>
                    <input type="date" name="data_nasc" className="form-control" value={inputs.data_nasc} onChange={handleInputsValueChanged} />
                  </div>


                  {/* <div className="form-group">
                    <label>Tipo de utilizador</label>

                    <select className="form-control select" onChange={(e) => handleSelectTipoChange(e.target.value)}
                      value={tipo} >
                      <option value="1" selected={inputs.tipo == 1}>
                        Administrador
                      </option>
                      <option value="2" selected={inputs.tipo == 2}>
                        Formador
                      </option>
                      <option value="3" selected={inputs.tipo == 3}>
                        Formando
                      </option>

                    </select>

                  </div> */}

                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Telefone</label>
                    <input id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>Nivel</label>

                    <select onChange={(e) => handleSelectAcessoChange(e.target.value)}
                      value={nivelSelecionado} className="form-control select">
                      {niveis?.map((cdata) => (
                        <option key={cdata.id} value={cdata.id}>
                          {cdata.designacao}
                        </option>
                      ))}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>NIF</label>
                    <input type="text" name="nif" value={inputs.nif} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Nivel Académico</label>
                    <input type="text" name="nivel_academico" value={inputs.nivel_academico} onChange={handleInputsValueChanged} />
                  </div>



                  <div className="form-group">
                    <label>Endereço</label>
                    <input type="text" name="endereco" value={inputs.endereco} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select
                      className="form-control select"
                      name="galeria"
                      onChange={(e) =>
                        handleSelectGaleriaChange(e.target.value)
                      }
                      value={galeria}
                    >
                      <option value="1" selected={inputs.galeria == 1}>
                        Aparece
                      </option>
                      <option value="2" selected={inputs.galeria == 2}>
                        Não aparece
                      </option>
                    </select>
                  </div>

                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>
                  <div className="form-group">
                    <label>Resetar senha</label>

                    <a className="" onClick={() => handleResetSenha(id)}>
                      <i className="fa fa-key"></i>
                    </a>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>

                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Fotografia</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {fotografiaPreview ? (
                          <>
                            <img id="fotografia"
                              src={fotografiaPreview ? url_root + "/storage/" + fotografiaPreview : URL.createObjectURL(fotografia)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar fotografia</h4>
                          </>
                        ) : (

                          <>
                            <img id="fotografia"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar fotografia</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>
                </div>



                {/* Restante do código */}

                {
                  utilizador?.tipo == 3 ? (<>
                    <div className="col-lg-12">
                      <a onClick={submitForm} className="btn btn-submit me-2">
                        {loading ? <Spinner /> : 'Salvar'}
                      </a>
                      <a onClick={navegarParaLista} className="btn btn-cancel">
                        Cancelar
                      </a>
                    </div></>) : (<></>)
                }

              </div>
            </form>

          </div>
        </div>
        {/* /add */}






        {/* INICIO formandos da turma*/}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">

                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row table-responsive">


              {turmas == null ? <span>Carregando...</span> : <>


                <table className="table table-bordered table-responsive datanew dataTable no-footer">
                  <thead>
                    <tr>
                      <th colSpan="10">

                        CONSULTAS DO PACIENTE

                        <hr />

                      </th>
                    </tr>

                  </thead>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="form-control-user"
                        />
                      </th>
                      <th>Nº</th>
                      <th>Icon</th>
                      <th>Turma</th>
                      <th>Pagamento (Kz)</th>
                      <th># <br></br> [ Computador ]</th>
                      <th>#</th>
                      <th>#</th>
                      <th>Atendido</th>
                      <th>
                        Operações &nbsp;&nbsp;

                        <a data-tip="Pdf">
                          <img src={Pdf} alt="img" />
                        </a>&nbsp;


                        <a data-tip="Excel">
                          <img src={Excel} alt="img" />
                        </a>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      turmas?.map((cdata, index) => (
                        <tr key={index} className={cdata.situacao == 1 ? '' : 'text-danger'}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-control-user check-formando"
                              data-id={cdata.id}
                            />
                          </td>
                          <td className={cdata.situacao == 1 ? '' : 'text-danger'}>{index + 1}</td>
                          <td className={cdata.situacao == 1 ? 'text-center' : 'text-dangertext-center'}>
                            <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + CursoDaTurma(cdata?.turma?.curso_id)?.imagem} alt="fotografia" />
                          </td>
                          <td className={cdata.situacao == 1 ? '' : 'text-danger'}>

                            {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}  &nbsp;&nbsp;

                            <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata?.turma.id)}  >
                              <i className="fa fa-calendar text-warning"></i>
                            </span>

                          </td>
                          <td >
                            {cdata.pagamentos && cdata.pagamentos.length > 0 ? (
                              formatarNumero(calcularSomaTotalPagamentos(cdata.pagamentos), 0, '.', ',')
                            ) : (
                              0
                            )} <span className="text-danger">Falta [ {cdata?.pagamentos ? (
                              formatarNumero(cdata?.turma?.preco - calcularSomaTotalPagamentos(cdata?.pagamentos), 0, '.', ',')
                            ) : (
                              0
                            )} ]</span>
                          </td>

                          {cdata.situacao == 1 ? <>
                            <td>
                              Regular
                              <br></br>
                              [ {cdata.computador} ]
                            </td>

                          </> :
                            <>
                              <td>
                                <span className="text-danger">Desistente</span>
                                <br></br>
                                [ {cdata.computador} ]
                              </td>


                            </>
                          }

                          <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                            {cdata.nota}
                          </td>

                          {cdata.certificado == 0 || cdata.nota == 0 ? <>
                            <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                              Sem avaliação
                            </td></> :
                            <>
                              <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                                Por fazer
                              </td></>
                          }

                          <td className={cdata.situacao == 1 ? '' : 'text-danger'}>

                            {cdata?.atendente?.name.toUpperCase()}

                          </td>

                          <td>

                          <span className="icon" title="Ver e registar pagamento deste formando" onClick={() => handleAbrirModalPagamento(cdata.id, cdata?.turma_id, cdata?.turma?.paga_coletivo )}>
                                      <i className="fa fa-money text-warning"></i>
                                    </span>&nbsp;&nbsp;

                            <span className="icon" onClick={() => irParaTurma(cdata.turma.id)} title="editar dados da turma" >
                              <i className="fa fa-pencil"></i>
                            </span>&nbsp;&nbsp;

                            <span className="icon" title="Remover inscrição" onClick={() => handleRemoverInscricao(cdata.id)}>
                              <i className="fa fa-trash text-danger"></i>
                            </span>

                          </td>
                        </tr>))
                    }
                  </tbody>
                </table>


              </>}


            </div>
          </div>
        </div>

        {/* FIM rmas do formando */}






        {/* INICIO pagamentos do formando*/}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">

                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">


              {pagamentos_turma == null ? <span>Carregando...</span> : <>


                <table className="table table-bordered datanew dataTable no-footer">
                  <thead>
                    <tr>
                      <th colSpan="8">
                        PAGAMENTOS DO PACIENTE
                        <hr />
                      </th>
                      <th>

                      </th>
                    </tr>

                  </thead>
                  <thead>
                    <tr>
                      <th>Valor (kz)</th>
                      <th>Percentagem</th>
                      <th>Data</th>
                      <th>Tipo</th>
                      <th>Consulta</th>
                      <th>Comprovativo</th>
                      <th>Atendido</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pagamentos_turma?.map((cdata, index) => (
                        <tr key={index}>

                          {cdata.valor >= cdata?.turma?.preco ? <>
                            <td title={cdata.observacao} className="text-success">
                              {formatarNumero(cdata.valor, 2, ',', '.')}
                            </td>
                          </> : <></>
                          }

                          {cdata?.turma?.preco / 2 <= cdata.valor && cdata.valor < cdata?.turma?.preco ? <>
                            <td title={cdata.observacao} className="text-warning">
                              {formatarNumero(cdata.valor, 2, ',', '.')}
                            </td>
                          </> : <></>
                          }

                          {cdata?.turma?.preco / 2 > cdata.valor ? <>
                            <td title={cdata.observacao} className="text-danger">
                              {formatarNumero(cdata.valor, 2, ',', '.')}
                            </td>
                          </> : <></>
                          }



                          <td>
                            {formatarNumero(cdata.valor * 100 / cdata?.turma?.preco, 2, ',', '.')}%
                          </td>

                          <td>
                            {format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')}
                          </td>

                          <td>
                            {cdata.tipo_pagamento == 1 ? 'Dinheiro' : cdata.tipo_pagamento == 2 ? 'Transferência' : cdata.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                          </td>

                          <td>
                            {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}  &nbsp;&nbsp;

                            <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata?.turma.id)}  >
                              <i className="fa fa-calendar text-warning"></i>
                            </span>
                          </td>

                          <td className="text-center">
                            <img className="img-profile rounded-square" width="50" height="50" src={url_root + "/storage/" + cdata.comprovativo} alt="fotografia" />
                          </td>
                          <td>
                            {cdata?.atendente?.name.toUpperCase()}
                          </td>
                          <td>
                            <span className="icon" title="Remover pagamento"
                              onClick={() => remover_pagamento(cdata.id)}>
                              <i className="fa fa-trash"></i>
                            </span>
                          </td>
                        </tr>))
                    }
                  </tbody>




                </table>


              </>}




            </div>
          </div>
        </div>

        {/* FIM pagamentos do formando */}



        {
          inputs == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="modal_pagamento" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-money"></i>&nbsp; Registar pagamento</h5>

                    <a id="trigger_modal_pagamento"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_pagamento">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

          

                    <form>

                      <div className="form-group">

                        <input className="form-control bg-primary text-white" type="text" readOnly value={inputs?.name + ' | ' + inputs?.telefone + ' | ' + inputs?.email + ' | ' + inputs?.nif} />

                      </div>

                      <hr />

                      <div className="row">

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>VALOR PAGO</label>
                            <input type="number" name="valor3" id="valor3" className="form-control" value={valor3} onChange={e => setValor3(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TIPO</label>
                            <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                              <option value={1}>Dinheiro</option>
                              <option value={2}>Transferência</option>
                              <option value={3}>TPA</option>
                              <option value={4}>Outro</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label>DATA</label>
                            <input type="date" name="dataInsc3" id="dataInsc3" className="form-control" placeholder="" value={data_insc3} onChange={e => setDataInsc3(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>OBSERVAÇÃO</label>
                            <textarea className="form-control" name="observacao" value={observacao3} onChange={e => setObservacao3(e.target.value)} ></textarea>
                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary"
                              onClick={submitPagamentoForm}>

                              {loading ? <Spinner /> : 'REGISTAR O PAGAMENTO'}

                            </a>

                          </div>

                        </div>

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>Comprovativo de pagamento</label>
                            <div className="image-upload image-upload-new">
                              <input type="file" onChange={handleFileChange200} />
                              <div className="image-uploads">
                                {imagemPreview200 ? (
                                  <>
                                    <img id="imagem200"
                                      src={imagemPreview200 ? imagemPreview200 : URL.createObjectURL(imagem200)}
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>
                                ) : (

                                  <>
                                    <img id="imagem"
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>

                                )}
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }





        {/* INICIO PERMISSÕES */}

        {
          permissaoAcessoAtualizar == 2 ? <>
  <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">
                        Selecionar tudo
                        <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="productdetails product-respon">
                  <ul>

                    {loading ? (<Spinner />) : (


                      accoes?.map((cdata, index) => {

                        // Find the corresponding object in your array
                        const matchingObject = permissoes?.find(obj => {
                          return obj.accao_id == cdata.id && obj.nivel_id == nivelSelecionado;
                        });

                        return (
                          <li key={index}>
                            <h4 title={cdata.descricao}>{cdata.designacao}</h4>
                            <div className="input-checkset">
                              <ul>

                                <li>
                                  <label className="inputcheck">
                                    Ver
                                    <input key={cdata.id + 'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ver')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Adicionar
                                    <input type="checkbox" key={cdata.id + 'adicionar'} checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'adicionar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Atualizar
                                    <input type="checkbox" key={cdata.id + 'atualizar'} checked={matchingObject && matchingObject.atualizar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'atualizar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Ativar/Desativar
                                    <input type="checkbox" key={cdata.id + 'ativar'} checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ativar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Remover
                                    <input type="checkbox" key={cdata.id + 'remover'} checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'remover')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Selecionar tudo
                                    <input type="checkbox" checked={matchingObject && matchingObject.ver == 1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar && matchingObject.remover} key={cdata.id + 'select'} onChange={() => handleSelectRow(index, cdata.id)} className={'chk_selecionar_linhas_' + index + ' chk_selecionar_linhas'} code={cdata.id} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                              </ul>
                            </div>
                          </li>
                        );
                      })


                    )}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
</>:<></>
        }

      

        {/* FIM PERMISSÕES */}

      </div>

      <ToastContainer />

    </div>

  );
};

NewformandoEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoAcessoAtualizar: PropTypes.bool.isRequired,
};

export default NewformandoEdit;
