/* eslint-disable react/prop-types */
//import React from 'react';
import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ParagrafoLista from './paragrafolista';

import AuthUser from '../../components/AuthUser'; 


const ParagrafoIndex = ({ match }) => {

     // eslint-disable-next-line no-unused-vars
     const { verificarPermissao } = AuthUser();
     // eslint-disable-next-line no-unused-vars
     const [permissaoVer, setPermissaoVer] = useState(false);
     const [permissaoAtivar, setPermissaoAtivar] = useState(false);
     const [permissaoRemover, setPermissaoRemover] = useState(false);
 
     useEffect(() => {
 
         setPermissaoVer(verificarPermissao('SALA','ver'));
         setPermissaoAtivar(verificarPermissao('SALA','ativar'));
         setPermissaoRemover(verificarPermissao('SALA','remover'));
 
     },);
 
     return (
    <Switch>

        <Route path={`${match.url}/paragrafoLista`}  render={(props) => <ParagrafoLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />

    </Switch>)
}

export default ParagrafoIndex