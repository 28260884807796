/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from "prop-types";

const BannerLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {
  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } =
    AuthUser();
  const [salas, setsala] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState("");

  const [designacaoFilter, setDesignacaoFilter] = useState("");
  const [descricaoFilter, setDescricaoFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedsalaIds, setSelectedsalaIds] = useState([]);

 

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getsala()
          await getsala();
        } catch (error) {
          console.error("Erro ao obter salas:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("designacao", designacaoFilter);
      formData.append("descricao", descricaoFilter);
      formData.append("estado", estadoFilter);

      const response = await http.post("/banner/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.salas);
      setsala(response.data.salas);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedsalaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {
    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar salas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post("/banner/eliminar-multiple", {
              selectedsalaIds,
            });

            getsala();

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {
    if (permissaoAtivar) {
      try {
        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post("/banner/activate-multiple", {
          selectedsalaIds,
          newState,
        });

        getsala();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getsala = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/banners");

      //console.log("salas :");
      //console.log(res.data.salas);

      setsala(res.data.salas);

      setLoading(false);

      /* pdf e excel */

      // Verifica se o array de salas está vazio antes de prosseguir
      if (salas.length === 0) {
        console.warn("Array de salas vazio.");
        return;
      }

      const data = salas.map((sala) => ({
        name: sala.designacao,
        descricao: sala.descricao,
        estado: sala.estado === 1 ? "Ativo" : "Desativado",
      }));

      // Preenche os valores no estado após a conclusão de getsala()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(["Designação", "Descrição", "Estado"]);
      setFicheiroNome("salas");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("sala não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {
    if (permissaoRemover) {
      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {
        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {
      setDesignacaoFilter("");
      setDescricaoFilter("");
      setEstadoFilter();

      getsala();
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/banner/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getsala();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {
    if (permissaoRemover) {
      try {
        const formData = new FormData();
        formData.append("id", id);

        const response = await http.post("/banner/eliminar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getsala();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          <Link
            to={`/dream-pos/banner/banneredit/${record.id}`}
            style={{ width: "70%" }}
            className="product-img"
          >
            <img
              alt="imagem"
              className="rounded-3"
              src={url_root + "/storage/" + record.imagem}
              style={{ maxWidth: "50px" }}
            />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      title: "Pagina",
      dataIndex: "pagina",
      sorter: (a, b) => a.pagina.length - b.pagina.length,
      render: (text, record) => (
        <div>
          <div>
            {" "}
            {record.pagina == 1
              ? "Inicio"
              : record.pagina == 2
              ? "Sobre Nós"
              : record.pagina == 3
              ? "Especialistas"
              : record.pagina == 4
              ? "Faqs"
              : record.pagina == 5
              ? "Unidades"
              : record.pagina == 6
              ? "Serviços"
              : record.pagina == 7
              ? "Noticias"
              : "Contacto"}
          </div>
        </div>
      ),
    },
    {
      title: "Titulo",
      dataIndex: "designacao",
      sorter: (a, b) => a.designacao.length - b.designacao.length,
    },
    {
      title: "Ordem",
      dataIndex: "acentos",
      sorter: (a, b) => a.acentos.length - b.acentos.length,
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      sorter: (a, b) => a.descricao.length - b.descricao.length,
    },
    {
      title: "Estado",
      dataIndex: "estado", // Substituir por o nome do campo correto da API
      key: "estado",
      render: (e, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            id={`sala${record.id}`}
            className="check"
            defaultChecked={e}
            onChange={() => handleAtivarDesativar(record.id, !record.estado)}
            name={`estado_${record.id}`}
          />
          <label htmlFor={`sala${record.id}`} className="checktoggle">
            checkbox
          </label>
        </div>
      ),
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>
          <div className="icons-container">
            <Link
              title="editar dados sala"
              className="me-3"
              to={`/dream-pos/banner/banneredit/${record.id}`}
            >
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div
              title="Eliminar esta sala"
              className="confirm-text"
              onClick={() => confirmEliminar(record.id)}
            >
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>
        </>
      ),
    },
  ];

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">
                  <i
                    alt="Image"
                    className="header-image fa fa-university border p-3 rounded-3"
                  ></i>
                  <div>
                    <h4>Gestão de banners</h4>
                    <h6>Lista de banners</h6>
                  </div>
                </div>
                <div className="page-btn">
                <Link to="/dream-pos/banner/novobanner" className="btn btn-added">
                    <img src={PlusIcon} alt="img" className="me-1" />
                    Adicionar banner
                  </Link>
                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">
                  <Tabletop
                    inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    setDesignacaoFilter={setDesignacaoFilter}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Designação"
                              value={designacaoFilter}
                              onChange={(e) =>
                                setDesignacaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Descrição"
                              value={descricaoFilter}
                              onChange={(e) =>
                                setDescricaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => setEstadoFilter(e.target.value)}
                            >
                              <option value="-1">Todos estados</option>
                              <option value="0">Desativado</option>
                              <option value="1">Ativado</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img
                                src={search_whites}
                                alt="img"
                                onClick={handleFilterSubmit}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">
                    {loading ? (
                      <span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (
                      /*<Table columns={columns} dataSource={salas} />*/

                      <Datatable
                        columns={columns}
                        dataSource={salas}
                        onSelectedRowsChange={(selectedsalaIds) =>
                          setSelectedsalaIds(selectedsalaIds)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>

         
          </div>

          <ToastContainer />
        </>
        );{" "}
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

BannerLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default BannerLista;
