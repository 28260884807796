/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const NewuserEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoAcessoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();

  const [nivelSelecionado, setNivelSelecionado] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [utilizador, setUtilizador] = useState(null);
  const [loading, setLoading] = useState(false);
  const [niveis, setNiveis] = useState([]);
  const [tipo, setTipoSelecionado] = useState(1);
  const [genero, setGenero] = useState(1);

  const [fotografia, setSelectedFile] = useState(null);
  const [fotografiaPreview, setFotografiaPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const [galeria, setGaleria] = useState(1);
  
  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  };

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis');

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', nivelSelecionado);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões :');
      console.log(res.data.permissoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      console.log('Acções');
      //console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

  /* fim get permissões */

  /* CONSTS INICIO PERMISSÕES */

  const [accoes, setAccoes] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  /* CONSTS FIM PERMISSÕES */

  const navegarParaLista = () => {
    history.push('/dream-pos/users/userlists');
  };

  useEffect(() => {

    setTipoSelecionado(1); //Para utilizador normal

    const fetchData = async () => {
      try {

        console.log('Fetching niveis...');
        await getNiveis();
        console.log('Niveis:', niveis);

        console.log('Fetching user data...');
        const userResponse = await http.get('/utilizador/' + id);
        console.log('User data:', userResponse.data.utilizador);

        setUtilizador(userResponse.data.utilizador);

        if (userResponse.data.utilizador?.tipo == 1) {

            setInputs(userResponse.data.utilizador);
            setNivelSelecionado(userResponse.data.utilizador.nivel_id);
            setGenero(userResponse.data.utilizador.genero);
            setTipoSelecionado(userResponse.data.utilizador.tipo);
            setFotografiaPreview(userResponse.data.utilizador.fotografia);

            console.log('Fetching permissoes...');
            try {

              setLoading(true);

              const formData = new FormData();
              formData.append('nivel_id', userResponse.data.utilizador.nivel_id);

              const res = await http.post('/permissoes', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              });

              setPermissoes(res.data.permissoes);

              console.log('Permissões :');
              console.log(res.data.permissoes);

            } catch (error) {
              console.error('Ocorreu um erro ao carregar os permissões:', error);
            } finally {
              setLoading(false);
            }

            console.log('Permissoes:', permissoes);

            console.log('Fetching accões...');
            await getAccoes();
            console.log('Acções:', accoes);
        }

        


      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [id]);


  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const handleResetSenha = (id) => {

    if (permissaoAtualizar) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer resetar a senha?",
        text: "Isso irá resetar a senha do usuário.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, resetar senha!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação para resetar a senha
          resetarSenha(id);
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const resetarSenha = async (id) => {

    if (permissaoAtualizar) {
      try {
        // Gere uma senha aleatória no cliente
        const senhaAleatoria = Math.random().toString(36).slice(-8);

        // Exiba a senha antes de enviar a solicitação
        alert(`Nova Senha: ${senhaAleatoria}`);

        // Envie uma solicitação para a API para resetar a senha
        const response = await http.post(`/resetar-senha/${id}`, { novaSenha: senhaAleatoria });

        // Exiba uma mensagem de sucesso
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


      } catch (error) {
        // Exiba uma mensagem de erro se ocorrer um problema
        toast.error('Ocorreu um erro ao resetar a senha. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getUtilizador = async () => {
    try {
      /* */
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizador/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.utilizador);

      setNivelSelecionado(res.data.utilizador.nivel_id);

      setFotografiaPreview(res.data.utilizador.fotografia);

      console.log('User :');
      console.log(res.data.utilizador);

      console.log('Accao_id :');
      console.log(nivelSelecionado);

      //getPermissoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/utilizador/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getUtilizador();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      nivel_id: name === 'nivel_id' ? value : prevInputs.nivel_id,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('fotografia').src = newFileURL;

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.name.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(inputs.email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);

          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateUtilizador();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const updateUtilizador = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', inputs.name);
      formData.append('email', inputs.email);
      formData.append('nivel_id', nivelSelecionado);
      formData.append('tipo', tipo);
      formData.append('nif', inputs.nif);
      formData.append('telefone', inputs.telefone);
      formData.append('data_nasc', inputs.data_nasc);
      formData.append('genero', genero);
      formData.append('nivel_academico', inputs.nivel_academico);
      formData.append('endereco', inputs.endereco);
      formData.append('fotografia', fotografia);
      formData.append('galeria', galeria);

      const response = await http.post('/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de utilizadores após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  /* INICIO PERMISSÕES */

  const handleCheckboxChange = async (nivelId, acaoId, action) => {

    if (permissaoAcessoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelId);
        formData.append('accao_id', acaoId);
        formData.append('tipo', action);

        // Set the value based on the current state (toggle)
        const valor = permissoes.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
        formData.append('value', valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post('/update-permissao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleAtivarDesativarLinha = async (nivelSelecionado, accao_id, valor) => {

    if (permissaoAcessoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelSelecionado);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);


        console.log(nivelSelecionado, accao_id, valor);

        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de nivelSelecionado e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(nivelSelecionado, accao_id, valor);
  };

  const handleSelectedAll = async () => {

    if (permissaoAcessoAtualizar) {

      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute('code');
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append('nivel_id', nivelSelecionado);
          formData.append('accao_id', accao_id);
          formData.append('valor', valor);

          const res = await http.post('/update-permissao-multipla', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectAcessoChange = (id) => {

    setNivelSelecionado(id);

    console.log(id);

    // Fetch new permissions after navigation
    getPermissoes(id);

  };

  /*const handleSelectTipoChange = (id) => {

    setTipoSelecionado(id);

  };*/


  const handleSelectGeneroChange = (id) => {

    setGenero(id);

  };

  /* FIM PERMISSÕES */


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de utilizadores</h4>
              <h6>Editar utilizador</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/users/newuser" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp; Add Novo Utilizador
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nome de utilizador</label>
                    <input type="text" name="name" id="nome" value={inputs.name} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>E-mail</label>
                    <input id="email" name="email" type="text" value={inputs.email} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Genero</label>

                    <select className="form-control select" onChange={e => handleSelectGeneroChange(e.target.value)} value={genero} >
                      <option value="1" selected={inputs.genero == 1}>
                        Feminino
                      </option>
                      <option value="2" selected={inputs.genero == 2}>
                        Masculino
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Nascimento</label>
                    <input type="date" name="data_nasc" className="form-control" value={inputs.data_nasc} onChange={handleInputsValueChanged} />
                  </div>


                  {/* <div className="form-group">
                    <label>Tipo de utilizador</label>

                    <select className="form-control select" onChange={(e) => handleSelectTipoChange(e.target.value)}
                      value={tipo} >
                      <option value="1" selected={inputs.tipo == 1}>
                        Administrador
                      </option>
                      <option value="2" selected={inputs.tipo == 2}>
                        Formador
                      </option>
                      <option value="3" selected={inputs.tipo == 3}>
                        Formando
                      </option>

                    </select>

                  </div> */}

                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Telefone</label>
                    <input id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>Nivel</label>

                    <select onChange={(e) => handleSelectAcessoChange(e.target.value)}
                      value={nivelSelecionado} className="form-control select">
                      {niveis.map((cdata) => (
                        <option key={cdata.id} value={cdata.id}>
                          {cdata.designacao}
                        </option>
                      ))}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>NIF</label>
                    <input type="text" name="nif" value={inputs.nif} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Nivel Académico</label>
                    <input type="text" name="nivel_academico" value={inputs.nivel_academico} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Endereço</label>
                    <input type="text" name="endereco" value={inputs.endereco} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select
                      className="form-control select"
                      name="galeria"
                      onChange={(e) =>
                        handleSelectGaleriaChange(e.target.value)
                      }
                      value={galeria}
                    >
                      <option value="1" selected={inputs.galeria == 1}>
                        Aparece
                      </option>
                      <option value="2" selected={inputs.galeria == 2}>
                        Não aparece
                      </option>
                    </select>
                  </div>

                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>
                  <div className="form-group">
                    <label>Resetar senha</label>

                    <a className="" onClick={() => handleResetSenha(id)}>
                      <i className="fa fa-key"></i>
                    </a>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>

                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Fotografia</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {fotografiaPreview ? (
                          <>
                            <img id="fotografia"
                              src={fotografiaPreview ? url_root + "/storage/" + fotografiaPreview : URL.createObjectURL(fotografia)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar fotografia</h4>
                          </>
                        ) : (

                          <>
                            <img id="fotografia"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar fotografia</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Restante do código */}

                {
                  utilizador?.tipo == 1?(<>
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div></>):(<></>)
                }

              </div>
            </form>

          </div>
        </div>
        {/* /add */}



        {/* INICIO PERMISSÕES */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">
                        Selecionar tudo
                        <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="productdetails product-respon">
                  <ul>

                    {loading ? (<Spinner />) : (


                      accoes.map((cdata, index) => {

                        // Find the corresponding object in your array
                        const matchingObject = permissoes.find(obj => {
                          return obj.accao_id == cdata.id && obj.nivel_id == nivelSelecionado;
                        });

                        return (
                          <li key={index}>
                            <h4 title={cdata.descricao}>{cdata.designacao}</h4>
                            <div className="input-checkset">
                              <ul>

                                <li>
                                  <label className="inputcheck">
                                    Ver
                                    <input key={cdata.id + 'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ver')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Adicionar
                                    <input type="checkbox" key={cdata.id + 'adicionar'} checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'adicionar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Atualizar
                                    <input type="checkbox" key={cdata.id + 'atualizar'} checked={matchingObject && matchingObject.atualizar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'atualizar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Ativar/Desativar
                                    <input type="checkbox" key={cdata.id + 'ativar'} checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'ativar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Remover
                                    <input type="checkbox" key={cdata.id + 'remover'} checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(nivelSelecionado, cdata.id, 'remover')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Selecionar tudo
                                    <input type="checkbox" checked={matchingObject && matchingObject.ver == 1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar && matchingObject.remover} key={cdata.id + 'select'} onChange={() => handleSelectRow(index, cdata.id)} className={'chk_selecionar_linhas_' + index + ' chk_selecionar_linhas'} code={cdata.id} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                              </ul>
                            </div>
                          </li>
                        );
                      })


                    )}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FIM PERMISSÕES */}

      </div>

      <ToastContainer />

    </div>

  );
};

NewuserEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoAcessoAtualizar: PropTypes.bool.isRequired,
};

export default NewuserEdit;
