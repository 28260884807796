import React,{useState, useEffect} from "react";
import { EditSet } from "../../EntryFile/imagePath";
import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const EmployeeProfile = () => {
  
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, logout, token } = AuthUser();

  const [nivelSelecionado, setNivelSelecionado] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [niveis, setNiveis] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fotografia, setSelectedFile] = useState(null);
  const [fotografiaPreview, setFotografiaPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/dashboard-banco');
  };

  useEffect(() => {
    getNiveis();
    getUtilizador();
  }, []);

  const getUtilizador = () => {
   
      setLoading(true); // Set loading to true when the request starts

      setInputs(user);

      setNivelSelecionado(user.nivel_id)

      setFotografiaPreview(user.fotografia);
   
  };

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis');

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      nivel_id: name === 'nivel_id' ? value : prevInputs.nivel_id,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('fotografia').src = newFileURL;

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    try {
      toggleLoading(true);

      // Basic form validation
      if (inputs.name.length < 2) {
        setError('O nome deve ter pelo menos dois caracteres.');
        setLoading(false);

        // Show a error toast
        toast.error('O nome deve ter pelo menos dois caracteres.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (!validateEmail(inputs.email)) {
        setError('O email deve ser válido.');
        toggleLoading(false);

        // Show a error toast
        toast.error('O email deve ser válido.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }


      await updateUtilizador();


      // If the API call is successful, navigate to the dashboard
      //navigate('/dashboard');

    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Utilizador não autenticado.');

        // Show a error toast
        toast.error('Utilizador não autenticado.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else {
        setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

        // Show a error toast
        toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } finally {
      toggleLoading(false);
    }
  };


  const updateUtilizador = async () => {
    try {
      const formData = new FormData();
      formData.append('id', user.id);
      formData.append('name', inputs.name);
      formData.append('email', inputs.email);
      formData.append('nivel_id', nivelSelecionado);
      formData.append('telefone', inputs.telefone);
      formData.append('fotografia', fotografia);

      const response = await http.post('/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de utilizadores após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          
        
            console.log("saindo...");
      
            if(token != undefined){
              logout();
              //return <Redirect to="/signIn" />;
              //navigate('/trainning/system/signIn');
              //props.history.push('/trainning/system/signIn');
              //history.push('');
              history.push('');
            }

        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };



  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Meu perfil</h4>
              <h6>informação pessoal</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">
                      
                    {fotografiaPreview ? (
                          <>
                            <img id="fotografia"
                              src={fotografiaPreview ? url_root + "/storage/" + fotografiaPreview : URL.createObjectURL(fotografia)}
                              alt="Pré-visualização"
                            />
                          </>
                        ) : (

                          <>
                            <img id="fotografia"
                              alt="Pré-visualização"
                            />
                          </>

                        )}

                      <div className="profileupload">
                      <input type="file" onChange={handleFileChange} id="imgInp" />
                        <a href="#">
                          <img src={EditSet} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{ user.name }</h2>
                      <h4>Atualize sua foto e detalhes pessoais.</h4>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <button onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                    </button>
                    <button onClick={navegarParaLista} className="btn btn-cancel">Cancelar</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Nome de utilizador</label>
                    <input type="text"  name="name" id="nome" value={inputs.name} onChange={handleInputsValueChanged} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input id="email" name="email" type="text" value={inputs.email} onChange={handleInputsValueChanged} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Telefone</label>
                    <input  id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Nivel</label>

                    <select onChange={(e) => setNivelSelecionado(e.target.value)}
                      value={nivelSelecionado} className="form-control select">
                      {niveis.map((cdata) => (
                        <option key={cdata.id} value={cdata.id}>
                          {cdata.designacao}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
              
                <div className="col-12">
                  <button onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : 'Salvar'}
                  </button>
                  <button onClick={navegarParaLista} className="btn btn-cancel">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>

        <ToastContainer />

      </div>
    </>
  );
};

export default EmployeeProfile;
