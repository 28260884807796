/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

import {
  EditIcon,
  DeleteIcon,
  PlusIcon,
} from "../../EntryFile/imagePath";
import { ModalParagrafoEditar } from "../modal/modalParagrafoEditar";
import { ModalParagrafoNovo } from "../modal/modalParagrafoNovo";

const CursoEdit = ({ permissaoAtivar, permissaoAtualizar }) => {
  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [galeria, setGaleria] = useState(1);
  const [nivel, setNivel] = useState(1);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  /* TABELA MODAL FORMADORES */

  const [colaborador, setColaborador] = useState([]);
  const [formadores, setFormadores] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [moduloSelecionado, setModulo] = useState([]);

  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [horas, setHoras] = useState("");
  const [ordem, setOrdem] = useState(0);
  const [classificacao, setClassificacao] = useState(0);

  const [paragrafo_id, setParagrafoID] = useState(0);
  const [tituloParam, setTituloParam] = useState(0);
  const [descricaoParam, setDescricaoParam] = useState("");
  const [ordemParam, setOrdemParam] = useState(1);
  const [iconeParam, setIcone] = useState("");
  const [imagemParam, setImagem] = useState("");

  const [paragrafos, setParagrafos] = useState([]);


  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [colaboradoresSelecionados, setColaboradoresSelecionados] = useState(
    []
  );

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        selectedColaboradores.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);
  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {
    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        const colaboradorId = checkbox.dataset.id;
        console.log("Colaborador ID selecionado:", colaboradorId);
        selectedColaboradores.push(colaboradorId);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);
  };

  const vincularFormadorCurso = async () => {
    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (colaboradoresSelecionados.length > 0) {
        Swal.fire({
          title: "Tem certeza ?",
          text:
            "Pretende associar os doctores selecionados a consulta [ " +
            inputs?.designacao +
            " ] ?.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, associar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post("/curso/formadores/associar", {
                colaboradoresSelecionados,
                id,
              });

              getFormadores();

              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error(
                "Ocorreu um erro ao associar formadores ao curso. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
            }
          }
        });
      } else {
        toast.error("Tem de selecionar pelo menos um formador", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormadorCurso = async () => {
    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (colaboradoresSelecionados.length > 0) {
        Swal.fire({
          title: "Tem certeza ?",
          text:
            "Pretende Desassociar os doctores selecionados da consulta [ " +
            inputs?.designacao +
            " ] ?.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, associar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post(
                "/curso/formadores/desassociar",
                {
                  colaboradoresSelecionados,
                  id,
                }
              );

              getFormadores();

              toast.success(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error(
                "Ocorreu um erro ao associar formadores ao curso. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
            }
          }
        });
      } else {
        toast.error("Tem de selecionar pelo menos um formador", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  /* FIM CONSTANTES TABELA MODAL FORMADORES */

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push("/dream-pos/curso/cursolista");
  };

  const irParaFormador = (id) => {
    const element = document.querySelector(".btn-close");

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push("/dream-pos/users/newformadoredit/" + id);
  };

  useEffect(() => {
    getCurso();
    mostrarParagrafos();
  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getCurso = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/curso/" + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.curso);
      setModulos(res.data.curso?.modulos);

      console.log("curso modulos : ", res.data.curso?.modulos);

      setNivel(res.data.curso.nivel);

      setimagemPreview(res.data.curso.imagem);

      const resultado = await http.get("/utilizadores/formadores");
      setColaborador(resultado.data.utilizadores);

      getFormadores();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getFormadores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/formadores/curso/" + id);

      setFormadores(res.data.formadores);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/curso/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getCurso();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de cursos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do curso. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  };

  const handleSelectNivelChange = (valor) => {
    setNivel(valor);
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById("imagem").src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs?.designacao.length < 2) {
          setError("A designação deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("A designação deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updatecurso();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updatecurso = async () => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("sigla", inputs.sigla);
      formData.append("designacao", inputs?.designacao);
      formData.append("nivel", nivel);
      formData.append("categoria", inputs.categoria);
      formData.append("duracao", inputs.duracao);
      formData.append("idioma", inputs.idioma);
      formData.append("preco", inputs.preco);
      formData.append("ordem", inputs.ordem);
      formData.append("descricao_curta", inputs.descricao_curta);
      formData.append("especificacao", inputs.especificacao);
      formData.append("requisitos", inputs.requisitos);
      formData.append("galeria", galeria);
      formData.append("imagem", imagem);

      const response = await http.post("/curso/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de cursos após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        },
      });
    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error(
        "Ocorreu um erro ao carregar o curso. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const handleAbrirModalNota = (modulo) => {
    setModulo(modulo);

    if (modulo != null) {
      setTitulo(modulo?.titulo);
      setDescricao(modulo?.descricao);
      setHoras(modulo?.horas);
      setOrdem(modulo?.ordem);
      setClassificacao(modulo?.classificacao);
    } else {
      setTitulo("");
      setDescricao("");
      setHoras("");
      setOrdem("");
      setClassificacao("");
    }

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById("trigger_modal_nota");

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }
  };

  const submeterNovoModulo = async () => {
    if (permissaoAtualizar) {
      try {
        if (titulo.length < 2) {
          // Show a error toast
          toast.error("O titulo deve ter mais de dois caracteres", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        const formData = new FormData();

        formData.append("id", 0);
        formData.append("curso_id", inputs?.id);
        formData.append("titulo", titulo);
        formData.append("descricao", descricao);
        formData.append("horas", horas);
        formData.append("ordem", ordem);
        formData.append("classificacao", classificacao);
        formData.append("estado", 1);

        setLoading(true);

        const response = await http.post("/curso/modulo/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setModulos(response?.data?.modulos);

        // Obtenha a referência do elemento
        const triggerModalPagamento = document.getElementById(
          "close_modulos_modal"
        );

        // Verifique se o elemento existe antes de chamar o click()
        if (triggerModalPagamento) {
          // Dispare o evento 'click'
          triggerModalPagamento.click();
        }

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateModulo = async () => {
    if (permissaoAtualizar) {
      try {
        //alert(titulo);

        if (titulo.length < 2) {
          // Show a error toast
          toast.error("O titulo deve ter mais de dois caracteres", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        setLoading(true);

        const formData = new FormData();

        formData.append("id", moduloSelecionado?.id);
        formData.append("curso_id", inputs?.id);
        formData.append("titulo", titulo);
        formData.append("descricao", descricao);
        formData.append("horas", horas);
        formData.append("classificacao", classificacao);
        formData.append("ordem", ordem);

        const response = await http.post("/curso/modulo/update", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setModulos(response?.data?.modulos);

        // Obtenha a referência do elemento
        const triggerModalPagamento = document.getElementById(
          "close_modulos_modal"
        );

        // Verifique se o elemento existe antes de chamar o click()
        if (triggerModalPagamento) {
          // Dispare o evento 'click'
          triggerModalPagamento.click();
        }

        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const confirmEliminarModulo = (id, curso_id) => {
    Swal.fire({
      title: "Tem a certeza que pretende remover?",
      text: "Não sera possivel reverter o processo!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      if (t.isConfirmed) {
        // Se o usuário confirmar, envie a solicitação
        handleEliminarModulo(id, curso_id);
      }
    });
  };

  const handleEliminarModulo = async (id, curso_id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("curso_id", curso_id);

      const response = await http.post("/curso/modulo/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setModulos(response?.data?.modulos);

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Atualize a lista de cursos após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmEliminar = (id) => {
    Swal.fire({
      title: "Tem a certeza que quer remover?",
      text: "Não sera possivel reverter o processo!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      if (t.isConfirmed) {
        // Se o usuário confirmar, envie a solicitação
        handleEliminar(id);
      }
    });
  };

  const handleEliminar = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      const response = await http.post("/paragrafo/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      mostrarParagrafos();

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Atualize a lista de salas após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmissionSuccess = () => {
    mostrarParagrafos();
  };

  const mostrarParagrafos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/paragrafos_de/6/" + id);

      setParagrafos(res.data.paragrafos);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAbrirModalEditarParagrafo = (
    id,
    titulo,
    descricao,
    icone,
    imagem,
    ordem
  ) => {
    setParagrafoID(id);
    setTituloParam(titulo);
    setDescricaoParam(descricao);
    setIcone(icone);
    setImagem(imagem);
    setOrdemParam(ordem);

    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById(
      "trigger_modal_editar_paragrafo"
    );

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }
  };

  const handleAbrirModalParagrafo = () => {
    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById(
      "trigger_modal_novo_paragrafo"
    );

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-book border p-3 rounded-3"
            ></i>
            <div>
              <h4>Gestão de registo de tipo de consulta</h4>
              <h6>Editar tipo de consulta</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/curso/novocurso" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp; Adicionar tipo de consulta
            </Link>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <form className="user">
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Designação</label>
                    <input
                      type="text"
                      name="designacao"
                      id="designacao"
                      value={inputs?.designacao}
                      onChange={handleInputsValueChanged}
                    />
                  </div>
                  <div className="form-group">
                    <label>Categoria</label>
                    <input
                      id="categoria"
                      name="categoria"
                      type="text"
                      value={inputs.categoria}
                      onChange={handleInputsValueChanged}
                    />
                  </div>
                  <div className="form-group">
                    <label>Icone</label>
                    <input
                      id="idioma"
                      name="idioma"
                      type="text"
                      value={inputs.idioma}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select
                      className="form-control select"
                      name="galeria"
                      onChange={(e) =>
                        handleSelectGaleriaChange(e.target.value)
                      }
                      value={galeria}
                    >
                      <option value="1" selected={inputs.galeria == 1}>
                        Aparece
                      </option>
                      <option value="2" selected={inputs.galeria == 2}>
                        Não aparece
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Descrição curta</label>
                    <textarea
                      className="form-control"
                      name="descricao_curta"
                      value={inputs.descricao_curta}
                      onChange={handleInputsValueChanged}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>Abreviação</label>
                    <input
                      id="sigla"
                      name="sigla"
                      type="text"
                      value={inputs.sigla}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() =>
                          handleAtivarDesativar(id, !inputs.estado)
                        }
                        name={`estado_${id}`}
                      />
                      <label
                        htmlFor={`user${id}`}
                        className="checktoggle"
                      ></label>
                    </div>
                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nível</label>

                    <select
                      className="form-control select"
                      name="nivel"
                      onChange={(e) => handleSelectNivelChange(e.target.value)}
                      value={nivel}
                    >
                      <option value="1" selected={inputs.nivel == 1}>
                        Básico
                      </option>
                      <option value="2" selected={inputs.nivel == 2}>
                        Médio
                      </option>
                      <option value="3" selected={inputs.nivel == 3}>
                        Avançado
                      </option>
                      <option value="4" selected={inputs.nivel == 4}>
                        Expert
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Duração ( horas )</label>
                    <input
                      id="duracao"
                      name="duracao"
                      type="text"
                      value={inputs.duracao}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  <div className="form-group">
                    <label>Preço</label>
                    <input
                      id="preco"
                      name="preco"
                      type="text"
                      value={inputs.preco}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  <div className="form-group">
                    <label>Ordem ( visualização )</label>
                    <input
                      id="ordem"
                      name="ordem"
                      type="text"
                      value={inputs.ordem}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  <div className="form-group">
                    <label>Espeficicações</label>
                    <textarea
                      className="form-control"
                      name="especificacao"
                      value={inputs.especificacao}
                      onChange={handleInputsValueChanged}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>Requisitos</label>
                    <textarea
                      className="form-control"
                      name="requisitos"
                      value={inputs.requisitos}
                      onChange={handleInputsValueChanged}
                    ></textarea>
                  </div>
                </div>

                {/* Restante do código */}
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem 500x500</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img
                              id="imagem"
                              src={
                                imagemPreview
                                  ? url_root + "/storage/" + imagemPreview
                                  : URL.createObjectURL(imagem)
                              }
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (
                          <>
                            <img id="imagem" alt="Pré-visualização" />
                            <h4>Carregar imagem</h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Restante do código */}
                <div className="col-lg-4">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : "Salvar"}
                  </a>

                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>

                <div className="col-lg-4">
                  <a
                    href="#"
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_formador"
                  >
                    <i className="fa fa-user"></i> Adicionar doctor
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* /add */}

        {/* INICIO SUB-áreas */}

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck"></label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              {inputs == null ? (
                <span></span>
              ) : (
                <>
                  {/* Add Event Modal */}
                  <div
                    id="modal_nota"
                    className="modal modal-md  fade"
                    role="dialog"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div className="modal-content modal-lg modal-center">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            {" "}
                            <i className="fa fa-table"></i>&nbsp; MODULO [{" "}
                            {moduloSelecionado?.titulo} ]
                          </h5>

                          <a
                            id="trigger_modal_nota"
                            href="#"
                            className=""
                            data-bs-toggle="modal"
                            data-bs-target="#modal_nota"
                          ></a>

                          <button
                            type="button"
                            className="btn btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="close_modulos_modal"
                          >
                            <span aria-hidden="true"></span>
                          </button>
                        </div>

                        <div className="modal-body">
                          <form>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Ordem</label>

                                  <input
                                    type="number"
                                    id="nota_input"
                                    className="form-control"
                                    value={ordem}
                                    onChange={(e) => setOrdem(e.target.value)}
                                    max={100}
                                    min={0}
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Titulo</label>

                                  <input
                                    type="text"
                                    onChange={(e) => setTitulo(e.target.value)}
                                    value={titulo}
                                    className="form-control"
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Horas</label>

                                  <input
                                    type="number"
                                    onChange={(e) => setHoras(e.target.value)}
                                    value={horas}
                                    className="form-control"
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Classificacção</label>

                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setClassificacao(e.target.value)
                                    }
                                    value={classificacao}
                                    className="form-control"
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Descricao</label>

                                  <textarea
                                    className="form-control"
                                    value={descricao}
                                    onChange={(e) =>
                                      setDescricao(e.target.value)
                                    }
                                  ></textarea>
                                </div>

                                <div className="form-group">
                                  <a
                                    className="btn btn-primary"
                                    onClick={() =>
                                      moduloSelecionado == null
                                        ? submeterNovoModulo()
                                        : updateModulo()
                                    }
                                  >
                                    {loading ? <Spinner /> : "SALVAR"}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Add Event Modal */}
                </>
              )}
            </div>

            {permissaoAtivar == 2 ? 
          <>
            <div className="row mt-5">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th colSpan="4">
                          MODULOS DA CONSULTA DE{" "}
                          {inputs?.designacao?.toUpperCase()}
                        </th>
                        <th>
                          <a
                            className="btn btn-danger"
                            onClick={() => handleAbrirModalNota(null)}
                          >
                            <i className="fa fa-times"></i>
                            NOVO MODULO
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ORDEM</th>
                        <th>TITULO</th>
                        <th>HORAS</th>
                        <th>CLASSIFICAÇÃO</th>
                        <th>OPERAÇÕES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modulos?.map((cdata, index) => (
                        <tr key={index}>
                          <td>{cdata.ordem}</td>
                          <td>{cdata.titulo}</td>
                          <td>{cdata.horas}</td>
                          <td>{cdata.classificacao}</td>
                          <td>
                            <span
                              className="icon text-white-50"
                              onClick={() => handleAbrirModalNota(cdata)}
                            >
                              <i className="fa fa-pencil text-primary"></i>
                            </span>
                            <span
                              className="icon text-white-50"
                              onClick={() =>
                                confirmEliminarModulo(cdata.id, inputs?.id)
                              }
                            >
                              <i className="fa fa-trash text-danger"></i>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            </>:<></>
        }
            
          </div>
        </div>



        <div className="form-group">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%">
              <thead>
                <tr>
                  <th>
                    <div className="page-btn">
                      <div
                        onClick={() => handleAbrirModalParagrafo(1, 1)}
                        className="btn btn-primary"
                      >
                        <img src={PlusIcon} alt="img" className="me-1" />
                        Adicionar paragrafo
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Ordem</th>
                  <th>Texto</th>
                </tr>
              </thead>
              <tbody>
                {paragrafos?.map((cdata, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      <img
                        className="img-profile rounded-3"
                        width="50"
                        height="50"
                        src={cdata.imagem != 'null' ? url_root + "/storage/" + cdata.imagem : url_root + "/storage/sample.WEBP"}
                        alt="fotografia"
                      />
                    </td>
                    <td>{cdata.ordem}</td>
                    <td>
                      <div className="icons-container">
                        <div
                          title="editar dados paragrafo"
                          className="me-3"
                          to="#"
                        >
                          <img
                            src={EditIcon}
                            alt="Editar"
                            onClick={() =>
                              handleAbrirModalEditarParagrafo(
                                cdata?.id,
                                cdata?.titulo,
                                cdata?.descricao,
                                cdata?.icone,
                                cdata?.imagem,
                                cdata?.ordem
                              )
                            }
                          />
                        </div>
                        <div
                          title="Eliminar esta paragrafo"
                          className="confirm-text"
                          onClick={() => confirmEliminar(cdata.id)}
                        >
                          <img src={DeleteIcon} alt="Remover" />
                        </div>
                      </div>

                      <div>{cdata?.titulo}</div>

                      <div className="row">
                        <div className="col-sm-2">{cdata?.descricao}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


        {/* FIM SUB-áreas */}

        {/* INICIO SUB-áreas */}

        
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="input-checkset">
                      <ul>
                        <li>
                          <label className="inputcheck"></label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <table className="table table-bordered" width="100%">
                        <thead>
                          <tr>
                            <th colSpan="6">
                              DOCTORES DA CONSULTA DE{" "}
                              {inputs?.designacao?.toUpperCase()}
                            </th>
                            <th>
                              <a
                                className="btn btn-danger"
                                onClick={desvincularFormadorCurso}
                              >
                                <i className="fa fa-times"></i>
                                DESASSOCIAR DOCTORES
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="form-control-user"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange}
                              />
                            </th>
                            <th>Nº</th>
                            <th>Foto</th>
                            <th>Nome</th>
                            <th>Tel</th>
                            <th>Email</th>
                            <th>Operações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formadores?.map((cdata, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  className="form-control-user check-colaborador"
                                  onChange={handleCheckBoxChange}
                                  data-id={cdata.formador.id}
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td className="text-center">
                                <img
                                  className="img-profile rounded-circle"
                                  width="40"
                                  height="40"
                                  src={
                                    url_root +
                                    "/storage/" +
                                    cdata.formador.fotografia
                                  }
                                  alt="fotografia"
                                />
                              </td>
                              <td>{cdata.formador.name}</td>
                              <td>{cdata.formador.telefone}</td>
                              <td>{cdata.formador.email}</td>
                              <td>
                                <span
                                  className="icon text-white-50"
                                  onClick={() =>
                                    irParaFormador(cdata.formador.id)
                                  }
                                >
                                  <i className="fa fa-pencil"></i>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          

        {/* FIM SUB-áreas */}

        {/* Add Event Modal */}

       
        <div
          id="add_formador"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add DOCTOR</h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="procurar..."
                    />

                    <a
                      className="btn btn-primary"
                      onClick={vincularFormadorCurso}
                    >
                      ADICIONAR SELECIONADOS
                    </a>
                  </div>

                  <div className="form-group">
                    <div className="table-responsive">
                      <table
                        className="table table-dark table-bordered"
                        id="dataTable"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="form-control-user"
                                id="checkgeral"
                                name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange}
                              />
                            </th>
                            <th>Nº</th>
                            <th>Foto</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Operações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {colaborador?.map((cdata, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  className="form-control-user check-colaborador"
                                  onChange={handleCheckBoxChange}
                                  data-id={cdata.id}
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td className="text-center">
                                <img
                                  className="img-profile rounded-circle"
                                  width="40"
                                  height="40"
                                  src={
                                    url_root + "/storage/" + cdata.fotografia
                                  }
                                  alt="fotografia"
                                />
                              </td>
                              <td>{cdata.name}</td>
                              <td>
                                {cdata.telefone}
                                <br />
                                {cdata.email}
                              </td>
                              <td>
                                <span
                                  className="icon text-white-50"
                                  onClick={() => irParaFormador(cdata.id)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

       
        {/* /Add Event Modal */}
      </div>


      <ModalParagrafoNovo 
        onSubmissionSuccess={onSubmissionSuccess}
        tipo={6}
        tipo_id={id}
      />

      <ModalParagrafoEditar
        onSubmissionSuccess={onSubmissionSuccess}
        tipo={6}
        tipo_id={id}
        id={paragrafo_id}
        tituloParam={tituloParam}
        descricaoParam={descricaoParam}
        iconeParam={iconeParam}
        imagemParam={imagemParam}
        ordemParam={ordemParam}
      />
      

      <ToastContainer />


    </div>
  );
};

CursoEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
};

export default CursoEdit;
