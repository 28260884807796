/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from "react-router-dom";

import Swal from "sweetalert2";

import PropTypes from "prop-types";
import { ModalParagrafoEditar } from "../modal/modalParagrafoEditar";
import { ModalParagrafoNovo } from "../modal/modalParagrafoNovo";

import {
  EditIcon,
  DeleteIcon,
  PlusIcon,
} from "../../EntryFile/imagePath";

const NoticiaEdit = ({ permissaoAtivar, permissaoAtualizar }) => {
  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState({});

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const [paragrafo_id, setParagrafoID] = useState(0);
  const [tituloParam, setTitulo] = useState(0);
  const [descricaoParam, setDescricao] = useState("");
  const [ordemParam, setOrdem] = useState(1);
  const [iconeParam, setIcone] = useState("");
  const [imagemParam, setImagem] = useState("");

  const [paragrafos, setParagrafos] = useState([]);

  const navegarParaLista = () => {
    history.push("/dream-pos/noticia/noticialista");
  };

  useEffect(() => {
    getSala();
    mostrarParagrafos();
  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const handleSelectPaginaChange = (id) => {
    setPagina(id);
  };

  const getSala = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/noticia/" + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.sala);
      setPagina(res.data.sala.pagina);

      //alert(inputs?.pagina);

      setimagemPreview(res.data.sala.imagem);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/noticia/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getSala();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do sala. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById("imagem").src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.titulo.length < 2) {
          setError("O designação da sala deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error(
            "O designação da sala deve ter pelo menos dois caracteres.",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );

          return;
        }

        await updatesala();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updatesala = async () => {
    if (permissaoAtualizar) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("titulo", inputs.titulo);
        formData.append("acentos", inputs.acentos);
        formData.append("descricao", inputs.descricao);
        formData.append("imagem", imagem);
        formData.append("pagina", pagina);

        const response = await http.post("/noticia/update", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de salas após o atualização bem-sucedida
            //history.push('/dream-pos/users/userlists');
            if (!permanecerNaPagina) {
              navegarParaLista();
            }
          },
        });
      } catch (error) {
        console.log("Erro:");
        console.log(error);

        toast.error(
          "Ocorreu um erro ao carregar o sala. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const confirmEliminar = (id) => {
    Swal.fire({
      title: "Tem a certeza que quer remover?",
      text: "Não sera possivel reverter o processo!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      if (t.isConfirmed) {
        // Se o usuário confirmar, envie a solicitação
        handleEliminar(id);
      }
    });
  };

  const handleEliminar = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      const response = await http.post("/paragrafo/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      mostrarParagrafos();

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Atualize a lista de salas após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmissionSuccess = () => {
    mostrarParagrafos();
  };

  const mostrarParagrafos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/paragrafos_de/7/" + id);

      //console.log("salas :");
      //console.log(res.data.salas);

      setParagrafos(res.data.paragrafos);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAbrirModalEditarParagrafo = (
    id,
    titulo,
    descricao,
    icone,
    imagem,
    ordem
  ) => {
    setParagrafoID(id);
    setTitulo(titulo);
    setDescricao(descricao);
    setIcone(icone);
    setImagem(imagem);
    setOrdem(ordem);

    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById(
      "trigger_modal_editar_paragrafo"
    );

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }
  };

  const handleAbrirModalParagrafo = () => {
    // Obtenha a referência do elemento
    const triggerModalNovoParagrafo = document.getElementById(
      "trigger_modal_novo_paragrafo"
    );

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalNovoParagrafo) {
      // Dispare o evento 'click'
      triggerModalNovoParagrafo.click();
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-university border p-3 rounded-3"
            ></i>
            <div>
              <h4>Gestão de noticias</h4>
              <h6>Editar noticia</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/noticia/novanoticia" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp; Add Novo Noticia
            </Link>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <form className="user">
              <div className="row">
                <div className="col-lg-9 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Titulo</label>
                    <input
                      type="text"
                      name="titulo"
                      id="designacao"
                      value={inputs.titulo}
                      onChange={handleInputsValueChanged}
                    />
                  </div>

                  {permissaoAtualizar == 2 ? (
                    <>
                      <div className="form-group">
                        <label>Pagina</label>

                        <select
                          className="form-control select"
                          onChange={(e) =>
                            handleSelectPaginaChange(e.target.value)
                          }
                          value={pagina}
                        >
                          <option value="1" selected={inputs?.pagina == 1}>
                            Inicio
                          </option>
                          <option value="2" selected={inputs?.pagina == 2}>
                            Sobre Nós
                          </option>
                          <option value="3" selected={inputs?.pagina == 3}>
                            Especilistas
                          </option>
                          <option value="4" selected={inputs?.pagina == 4}>
                            Faqs
                          </option>
                          <option value="5" selected={inputs?.pagina == 5}>
                            Unidades
                          </option>
                          <option value="6" selected={inputs?.pagina == 6}>
                            Serviços
                          </option>
                          <option value="7" selected={inputs?.pagina == 7}>
                            Noticias
                          </option>
                          <option value="8" selected={inputs?.pagina == 8}>
                            Contacto
                          </option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Ordem</label>
                        <input
                          type="text"
                          name="acentos"
                          id="acentos"
                          value={inputs.acentos}
                          onChange={handleInputsValueChanged}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="form-group">
                    <label>Descrição</label>
                    <textarea
                      name="descricao"
                      id="descricao"
                      value={inputs.descricao}
                      onChange={handleInputsValueChanged}
                      className="form-control"
                    >
                      {inputs.descricao}
                    </textarea>
                  </div>

                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() =>
                          handleAtivarDesativar(id, !inputs.estado)
                        }
                        name={`estado_${id}`}
                      />
                      <label
                        htmlFor={`user${id}`}
                        className="checktoggle"
                      ></label>
                    </div>
                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>
                </div>

                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem 1200X700</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img
                              id="imagem"
                              src={
                                imagemPreview
                                  ? url_root + "/storage/" + imagemPreview
                                  : URL.createObjectURL(imagem)
                              }
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (
                          <>
                            <img id="imagem" alt="Pré-visualização" />
                            <h4>Carregar imagem</h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : "Salvar"}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* /add */}

        <div className="form-group">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%">
              <thead>
                <tr>
                  <th>
                    <div className="page-btn">
                      <div
                        onClick={() => handleAbrirModalParagrafo(1, 1)}
                        className="btn btn-primary"
                      >
                        <img src={PlusIcon} alt="img" className="me-1" />
                        Adicionar paragrafo
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Ordem</th>
                  <th>Texto</th>
                </tr>
              </thead>
              <tbody>
                {paragrafos?.map((cdata, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      <img
                        className="img-profile rounded-3"
                        width="50"
                        height="50"
                        src={cdata.imagem != 'null' ? url_root + "/storage/" + cdata.imagem : url_root + "/storage/sample.WEBP"}
                        alt="fotografia"
                      />
                    </td>
                    <td>{cdata.ordem}</td>
                    <td>
                      <div className="icons-container">
                        <div
                          title="editar dados paragrafo"
                          className="me-3"
                          to="#"
                        >
                          <img
                            src={EditIcon}
                            alt="Editar"
                            onClick={() =>
                              handleAbrirModalEditarParagrafo(
                                cdata?.id,
                                cdata?.titulo,
                                cdata?.descricao,
                                cdata?.icone,
                                cdata?.imagem,
                                cdata?.ordem
                              )
                            }
                          />
                        </div>
                        <div
                          title="Eliminar esta paragrafo"
                          className="confirm-text"
                          onClick={() => confirmEliminar(cdata.id)}
                        >
                          <img src={DeleteIcon} alt="Remover" />
                        </div>
                      </div>

                      <div>{cdata?.titulo}</div>

                      <div className="row">
                        <div className="col-sm-2">{cdata?.descricao}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ModalParagrafoNovo
        onSubmissionSuccess={onSubmissionSuccess}
        tipo={7}
        tipo_id={id}
      />

      <ModalParagrafoEditar
        onSubmissionSuccess={onSubmissionSuccess}
        tipo={7}
        tipo_id={id}
        id={paragrafo_id}
        tituloParam={tituloParam}
        descricaoParam={descricaoParam}
        iconeParam={iconeParam}
        imagemParam={imagemParam}
        ordemParam={ordemParam}
      />

      <ToastContainer />
    </div>
  );
};

NoticiaEdit.propTypes = {
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoAtualizar: PropTypes.bool.isRequired,
};

export default NoticiaEdit;
